import React, { useEffect } from "react";
import { hydrateRoot, createRoot } from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { CookiesProvider } from "react-cookie";
import { loadableReady } from "@loadable/component";
import * as serviceWorker from "./serviceWorker";
import createStore from "./store";
import Redirector from "./components/Redirector";
import App from "./App";

const AppContainer = () => {
    let store = createStore(window.initialState);

    useEffect(() => {
        // Remove SSR data after ComponentDidMount
        if (window.initialState) {
            window.initialState = undefined;
        }
    });

    return (
        <CookiesProvider>
            <Router location>
                <Redirector>
                    <App store={store} />
                </Redirector>
            </Router>
        </CookiesProvider>
    );
};
// Suspense is not supported in SSR currently, and on server side we will use Static router, so this 2 is used here
loadableReady(() => {
    const root = document.getElementById("root");
    if (window.isDev) {
        createRoot(root).render(<AppContainer />);
    } else {
        hydrateRoot(root, <AppContainer />);
    }
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
