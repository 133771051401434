import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import IndeterminateButton from "../IndeterminateButton";
import {
    setUserInfo,
    removeAssemblies,
    assebliesWithUserData,
    setSession,
    removeSavedProperties,
    setMode,
} from "../../reducers/rootReducer";
import { useUserLogout } from "../../hooks/logout";
import { omit } from "../../support/helpers";
import { removePage } from "../../reducers/pagesReducer";

const omitKeys = ["onClick", "closeModal"];

/**
 * Logs the user out.
 */
const Logout = (props) => {
    const { children, closeModal } = props;
    const dispatch = useDispatch();
    const { execute, data, isLoading } = useUserLogout();

    useEffect(() => {
        if (data) {
            if (data.session) {
                dispatch(setUserInfo(data.session));
            } else {
                dispatch(setUserInfo({}));
                dispatch(setSession(uuidv4()));
            }
            dispatch(removeSavedProperties());
            dispatch(removePage({ pageType: "brokerAgents" }));
            dispatch(setMode("normal"));
            // These assemblies contain data about the user.
            dispatch(
                removeAssemblies(["USER_DETAILS", ...assebliesWithUserData])
            );
            if (closeModal) closeModal();
        }
    }, [data, closeModal, dispatch]);

    return (
        <IndeterminateButton
            isLoading={isLoading}
            onClick={() => execute()}
            {...omit(props, omitKeys)}
        >
            {children}
        </IndeterminateButton>
    );
};

export default Logout;
