import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Carousel from "react-multi-carousel";
import ListingItem from "../ListingItem";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import ButtonGroup from "./ButtonGroup";

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 992 },
        items: 4,
        slidesToSlide: 4, // optional, default to 1.
    },
    tablet: {
        breakpoint: { max: 992, min: 768 },
        items: 3,
        slidesToSlide: 3, // optional, default to 1
    },
    medium: {
        breakpoint: { max: 768, min: 576 },
        items: 2,
        slidesToSlide: 2, // optional, default to 1
    },
    mobile: {
        breakpoint: { max: 576, min: 0 },
        items: 1,
        slidesToSlide: 1, // optional, default to 1.
    },
};

// TODO: figure out how to lazy load the images here.

/**
 * Preview Carousel -- Used for the home page featured listings
 * @param {listingsPreview[]} listings list of listings preview objects
 * @param {*} props any props that would be added to a div
 */
const Preview = ({ listings, ...props }) => {
    const [deviceType, setDeviceType] = useState("desktop");
    const touchDevice = useSelector((state) => state.root.isMobile);
    const isTablet = useMediaQuery({ maxWidth: 992, minWidth: 768 });
    const isMedium = useMediaQuery({ maxWidth: 768, minWidth: 576 });
    const isSmall = useMediaQuery({ maxWidth: 576 });
    const isMobileOrTablet = touchDevice || isTablet || isMedium || isSmall;

    // The device type is set with a useEffect to avoid
    // hydration issues. The server cannot detect the device type
    // so we assume desktop, then conditionally set it after hydration.
    useEffect(() => {
        if (isTablet) {
            setDeviceType("tablet");
        } else if (isMedium) {
            setDeviceType("medium");
        } else if (isSmall) {
            setDeviceType("mobile");
        } else {
            setDeviceType("desktop");
        }
    }, [isTablet, isMedium, isSmall]);

    return (
        <div {...props}>
            <div className="w-100 position-relative">
                <CarouselWrapper
                    swipeable={isMobileOrTablet}
                    draggable={false}
                    showDots={true}
                    responsive={responsive}
                    ssr={true} // means to render carousel on server-side.
                    infinite={true}
                    shouldResetAutoplay={false}
                    keyBoardControl={true}
                    customTransition="all .5"
                    transitionDuration={500}
                    containerClass="carousel-container"
                    removeArrowOnDeviceType={["tablet", "medium", "mobile"]}
                    deviceType={deviceType}
                    dotListClass="custom-dot-list-style"
                    itemClass="carousel-item-padding-40-px"
                    sliderClass="align-items-stretch"
                    arrows={false}
                    renderButtonGroupOutside={true}
                    customButtonGroup={<ButtonGroup />}
                >
                    {listings.map((listing, i) => (
                        <ListingItem
                            key={`listing-item-${i}`}
                            className="pb-2 h-100"
                            listing={listing}
                        />
                    ))}
                </CarouselWrapper>
            </div>
        </div>
    );
};

const CarouselWrapper = styled(Carousel)`
    .react-multi-carousel-track {
        padding-bottom: 25px;
    }
`;

export default Preview;
