/*
 * ------------------------------------------------------------------------------------
 * DESCRIPTION:
 * ------------------------------------------------------------------------------------
 *      Reduces search data for search state.
 *
 * ------------------------------------------------------------------------------------
 * VERSION HISTORY: (NOTE: The version variables must be updated manually.)
 * ------------------------------------------------------------------------------------
 *                   Changed By
 *      Date         Initials    Version #  Sub-Version  Notes
 *      -----------  ----------  ---------  -----------  ------------------------------
 *      2022-11-15   ASC         2022319    1            Add agent_id to omitKeys.
 *
 * ------------------------------------------------------------------------------------
 * Set up the version information. This code needs to be in EVERY routine so version
 * information is accessible programmatically even if it is not used any other place
 * in the routine.
 *      Version......'2022319'    -- Julian date portion of the version.
 *      SubVersion...'1'        -- Additional version information.
 * ------------------------------------------------------------------------------------
 */

import { createSlice } from "@reduxjs/toolkit";
import { omit } from "../support/helpers";
import { setSelectedMarker } from "./mapReducer";

const initialState = {
    isFetching: undefined,
    setIsResetting: false,
    defaultRange: 20,
    locationValue: "",
    location: {},
    results: {},
    gis: {},
    searchHistory: {},
};

export const omitKeys = [
    "name",
    "distance_latitude",
    "distance_longitude",
    "distance_miles",
    "state_code",
    "INFO_state_code",
    "zipcode",
    "agent_id",
];
export const omitFilters = (filters) => omit(filters, omitKeys);

//TODO: turn this into thunk
export const resetSearch = (dispatch) => {
    if (dispatch) {
        dispatch(setIsResetting(true));
        dispatch(clearSearch());
        dispatch(setSelectedMarker(undefined));
        dispatch(setIsResetting(false));
    }
};

export const shouldSearch = (filters) => {
    if (!filters) return false;
    const filtersCopy = { ...filters };
    delete filtersCopy.state_code;
    delete filtersCopy.niche_item_id;
    delete filtersCopy.developmentID;
    return Object.keys(filtersCopy).length > 0;
};

const searchSlice = createSlice({
    name: "search",
    initialState,
    reducers: {
        setIsFetchingGis: (state, action) => {
            state.isFetchingGis = action.payload;
        },
        setIsFetching: (state, action) => {
            state.isFetching = action.payload;
        },
        setIsResetting: (state, action) => {
            state.isResetting = action.payload || false;
        },
        setSearchResultsByQueryParams: (state, action) => {
            const { location, data } = action.payload;
            delete data.dataGroup;
            delete data.dataSet;
            state.results[location] = data;
        },
        setGisResultsByQueryParams: (state, action) => {
            const { location, data } = action.payload;
            delete data.dataGroup;
            delete data.dataSet;
            state.gis[location] = data;
        },
        setSearchLocationByQueryParams: (state, action) => {
            const { location, data } = action.payload;
            delete data.dataGroup;
            delete data.dataSet;
            state.location[location] = data;
        },
        setPageNumber: (state, action) => {
            state[state.searchType].results.page_number = action.payload;
        },
        clearSearch: (state) => {
            state.state = {};
            state.niche = {};
            state.development = {};
            state.agent = {};
        },
        clearSearchByType: (state, action) => {
            const { searchType } = action.payload;
            state[searchType || state.searchType] = {};
        },
        setSearchHistory: (state, action) => {
            const { page, query, nicheItemId, developmentId } = action.payload;
            let type = "global";
            if (developmentId) {
                type = "development";
            } else if (nicheItemId) {
                type = "nicheItem";
            }
            state.searchHistory[page] = { query, type };
        },
        clearSearchHistory: (state, action) => {
            if (action.payload) {
                const updated = {};
                Object.keys(state.searchHistory).forEach((key) => {
                    const item = state.searchHistory[key];
                    if (item?.type !== action.payload) {
                        updated[key] = item;
                    }
                    state.searchHistory = updated;
                });
            } else {
                state.searchHistory = {};
            }
        },
    },
});

// Extract the action creators object and the reducer
const { actions, reducer } = searchSlice;
// Extract and export each action creator by name
export const {
    setIsFetchingGis,
    setIsFetching,
    setIsResetting,
    setTotalFilteredCount,
    setSearchLocation,
    clearSearch,
    clearSearchByType,
    setPageNumber,
    setSearchHistory,
    clearSearchHistory,
    setSearchLocationByQueryParams,
    setSearchResultsByQueryParams,
    setGisResultsByQueryParams,
} = actions;
// Export the reducer, either as a default or named export
export default reducer;
