import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./reducers/rootReducer";
import pageReducer from "./reducers/pagesReducer";
import searchReducer from "./reducers/searchReducer";
import mapReducer from "./reducers/mapReducer";
import featuredAgentReducer from "./reducers/featuredAgentReducer";
import { dataSystemsAPI } from "./services/dataSystemsAPI";

const createStore = (preloadedState) =>
    configureStore({
        reducer: {
            root: rootReducer,
            pages: pageReducer,
            search: searchReducer,
            map: mapReducer,
            agents: featuredAgentReducer,
            [dataSystemsAPI.reducerPath]: dataSystemsAPI.reducer,
        },
        preloadedState,
        // Adding the api middleware enables caching, invalidation, polling,
        // and other useful features of `rtk-query`.
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware().concat(dataSystemsAPI.middleware),
    });

export default createStore;
