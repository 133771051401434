/*
 * ------------------------------------------------------------------------------------
 * DESCRIPTION:
 * ------------------------------------------------------------------------------------
 *      ItemCard component
 *
 * ------------------------------------------------------------------------------------
 * VERSION HISTORY: (NOTE: The version variables must be updated manually.)
 * ------------------------------------------------------------------------------------
 *                   Changed By
 *      Date         Initials    Version #  Sub-Version  Notes
 *      -----------  ----------  ---------  -----------  ------------------------------
 *      2022-11-15   BL          22319      1            Re-structured files and renamed to index.js
 *
 */
import React from "react";
import useToggle from "../../hooks/useToggle";
import { Collapse, CardBody, Card } from "reactstrap";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

const ItemCard = ({
    title,
    children,
    className = "border-0",
    titleClassName,
    iconClassName,
    isCardBodyOpen = false,
}) => {
    const [isOpen, toggle] = useToggle(isCardBodyOpen);
    const IconName = isOpen ? FaChevronUp : FaChevronDown;
    return (
        <Card className={`${className}`}>
            <div
                className="d-flex text-primary align-items-center p-2"
                onClick={() => toggle()}
            >
                <div className={`d-inline-block me-2 ${titleClassName}`}>
                    {title}
                </div>
                <IconName className={`ms-auto ${iconClassName}`} />
            </div>
            <Collapse isOpen={isOpen}>
                <CardBody className="px-0 py-1">{children}</CardBody>
            </Collapse>
        </Card>
    );
};

export default ItemCard;
