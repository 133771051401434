import React from "react";
import { Link } from "react-router-dom";

const NavLink = ({ children, shouldOpenInNewWindow, ...props }) => {
    let target = "_self",
        rel;
    if (shouldOpenInNewWindow) {
        target = "_blank";
        rel = "noopener noreferrer";
    }
    return (
        <>
            {(props.to?.pathname || props.to).startsWith("http") ? (
                <a
                    className={props.className}
                    href={props.to?.pathname || props.to}
                    target={target}
                    rel={rel}
                >
                    {children}
                </a>
            ) : (
                <Link {...props}>{children}</Link>
            )}
        </>
    );
};

export default NavLink;
