/*
 * ------------------------------------------------------------------------------------
 * DESCRIPTION:
 * ------------------------------------------------------------------------------------
 *      CookieProvider component
 *
 * ------------------------------------------------------------------------------------
 * VERSION HISTORY: (NOTE: The version variables must be updated manually.)
 * ------------------------------------------------------------------------------------
 *                   Changed By
 *      Date         Initials    Version #  Sub-Version  Notes
 *      -----------  ----------  ---------  -----------  ------------------------------
 *      2022-11-22   BL          22326      1            Re-structured files and renamed to index.js
 *
 */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import { v4 as uuidv4 } from "uuid";
import { setSession } from "../../reducers/rootReducer";

const CookieProvider = ({
    children,
    testCookieSessionId = false,
    testSetCookie = false,
    testRemove = false,
}) => {
    const dispatch = useDispatch();
    const userSession = useSelector((state) => state.root.userSession);
    const stateSessionId = userSession.sessionID;
    let [{ sessionId: cookieSessionId, lhr_userdata }, setCookie, remove] =
        useCookies(["sessionId", "lhr_userdata"]);

    // Used for testing purposes
    if (testCookieSessionId)
        cookieSessionId =
            testCookieSessionId === "empty" ? null : testCookieSessionId;
    setCookie = testSetCookie ? testSetCookie : setCookie;
    remove = testRemove ? testRemove : remove;

    // Setting the cookie to expire in a year. This is so the browser keeps the cookie
    // even if the browser is closed.
    const getExpireDate = () =>
        new Date(new Date().setFullYear(new Date().getFullYear() + 1));

    // When setting the cookie, we force the path of / so that we are not accidently setting a session
    // for a path other than the whole site.

    useEffect(() => {
        // Session ID from server is INVALID. Creating new ID
        if (stateSessionId === "INVALID") {
            console.log(
                "SessionID is INVALID. Creating one and setting in state and cookie"
            );
            const sessionId = uuidv4();
            remove("sessionId");
            setCookie("sessionId", sessionId, {
                path: "/",
                expires: getExpireDate(),
            });
            dispatch(setSession(sessionId));
            // Session IDs not the same, using session from server
        } else if (
            stateSessionId &&
            cookieSessionId &&
            stateSessionId !== cookieSessionId
        ) {
            console.log("Session IDs not the same, using session from server");
            remove("sessionId");
            setCookie("sessionId", stateSessionId, {
                path: "/",
                expires: getExpireDate(),
            });
            // State session ID found but no cookie ID found. Setting cookie to match.
        } else if (stateSessionId && !cookieSessionId) {
            console.log(
                "State session ID found but no cookie ID found. Setting cookie to match."
            );
            remove("sessionId");
            setCookie("sessionId", stateSessionId, {
                path: "/",
                expires: getExpireDate(),
            });
            // Cookie session ID found, but no state session ID. Setting cookie to match
        } else if (cookieSessionId && !stateSessionId) {
            console.log(
                "Cookie session ID found, but no state session ID. Setting state to match"
            );
            dispatch(setSession(cookieSessionId));
            // No session IDs found. Creating one and setting in state and cookie
        } else if (!stateSessionId && !cookieSessionId) {
            console.log(
                "No session IDs found. Creating one and setting in state and cookie"
            );
            const sessionId = uuidv4();
            remove("sessionId");
            setCookie("sessionId", sessionId, {
                path: "/",
                expires: getExpireDate(),
            });
            dispatch(setSession(sessionId));
        }
    }, [stateSessionId, cookieSessionId, setCookie, remove, dispatch]);

    // This is a little "hack" added so the wordpress site can show the login text/icon on load
    useEffect(() => {
        if (userSession?.accessLevel > -1 && lhr_userdata?.accessLevel !== 1) {
            const cookieData = { ...userSession };
            delete cookieData.dIAccountInfo;
            setCookie("lhr_userdata", JSON.stringify(cookieData), {
                path: "/",
            });
        } else if (userSession?.accessLevel === -1 && lhr_userdata) {
            remove("lhr_userdata");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userSession, setCookie, remove, dispatch]);

    return <>{children}</>;
};

export default CookieProvider;
