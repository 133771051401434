import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { Switch, Route, Redirect } from "react-router-dom";
import { useHistory } from "react-router";
import { MapProvider } from "./contexts";
import CookieProvider from "./components/CookieProvider";
import routes from "./routes";
import {
    PageErrorBoundry,
    PageHandler,
    PageScroller,
} from "./components/Pages";
import SearchProvider from "./components/Search/SearchProvider";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter5Adapter } from "use-query-params/adapters/react-router-5";
import ToastProvider from "./components/ToastProvider";

function App({ store }) {
    const history = useHistory();

    // Set history location state and remove from window object.
    if (typeof window !== "undefined" && window !== null) {
        if (window.locationState) {
            // Setting originalLocation in the dataLayer to use in GTM
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                originalLocation:
                    document.location.protocol +
                    "//" +
                    document.location.hostname +
                    document.location.pathname +
                    document.location.search,
            });

            // Replacing initial history state on first load
            // To make sure the initial page history has the app state.
            const { pathname, search } = history.location;
            history.replace(`${pathname}${search || ""}`, window.locationState);

            // We want to delete window.locationState, but other parts of the app need
            // this data before location state is set by the history replace
            // the timeout is necessary so the data is still on the window object
            // for timing purposes.
            setTimeout(() => (window.locationState = undefined), 3000);
        }
    }

    return (
        <QueryParamProvider adapter={ReactRouter5Adapter}>
            <Provider store={store}>
                <MapProvider>
                    <CookieProvider>
                        <PageErrorBoundry>
                            <ToastProvider>
                                <PageHandler>
                                    <SearchProvider>
                                        <PageScroller>
                                            <Switch>
                                                <Redirect
                                                    exact
                                                    from="/index"
                                                    to="/"
                                                />
                                                {routes.map((route, i) => (
                                                    <Route
                                                        key={`route-key-${i}`}
                                                        {...route}
                                                    />
                                                ))}
                                            </Switch>
                                        </PageScroller>
                                    </SearchProvider>
                                </PageHandler>
                            </ToastProvider>
                        </PageErrorBoundry>
                    </CookieProvider>
                </MapProvider>
            </Provider>
        </QueryParamProvider>
    );
}

export default App;
