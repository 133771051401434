/*
 * ------------------------------------------------------------------------------------
 * DESCRIPTION:
 * ------------------------------------------------------------------------------------
 *      ValidatingInput component
 *
 * ------------------------------------------------------------------------------------
 * VERSION HISTORY: (NOTE: The version variables must be updated manually.)
 * ------------------------------------------------------------------------------------
 *                   Changed By
 *      Date         Initials    Version #  Sub-Version  Notes
 *      -----------  ----------  ---------  -----------  ------------------------------
 *      2022-11-14   BL          22318      1            Re-structured files and renamed to index.js
 *
 */
import React from "react";
import { FormFeedback, Label } from "reactstrap";
import { ErrorMessage } from "@hookform/error-message";
import ValidatingDatePicker from "./ValidatingDatePicker";
import ValidatingInput from "./ValidatingInput";

const ValidatingField = ({
    children,
    style = {},
    suffix,
    className = "mb-3",
    hideLabel,
    labelClasses = "",
    showFormField = true,
    showError = true,
    ...props
}) => {
    const {
        type,
        name,
        assembly,
        formFieldClasses = "",
        defaultValueOverride,
    } = props;
    if (!assembly) return null;
    const assemblyItem = assembly.find((item) => item.id === name);
    if (!assemblyItem) return null;
    const {
        fieldInfo: { label, default: formValue },
        requiredInfo,
    } = assemblyItem;
    const defaultValue = defaultValueOverride || formValue;
    const isRequired = requiredInfo?.required === 1;
    const {
        formState: { errors },
    } = props;

    return (
        <div className={className} style={style}>
            {!hideLabel && (
                <Label className={`mb-0 ${labelClasses}`}>
                    {label}
                    {isRequired ? "*" : ""}
                </Label>
            )}
            {showFormField ? (
                <>
                    {type === "datePicker" ? (
                        <ValidatingDatePicker {...props} />
                    ) : (
                        <ValidatingInput {...props} />
                    )}
                    {suffix}
                    {showError && errors && (
                        <ErrorMessage
                            errors={errors}
                            name={name}
                            render={({ message }) => (
                                <FormFeedback>{message}</FormFeedback>
                            )}
                        />
                    )}
                </>
            ) : (
                <div className={`px-2 ${formFieldClasses}`}>{defaultValue}</div>
            )}
        </div>
    );
};

export default ValidatingField;
