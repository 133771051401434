import React from "react";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import styled from "styled-components";

/**
 *
 * @param {*} props any props that would be added to a div
 * @returns Wrapped Select wrapper with an async react select & some default styles
 */
const WrappedAsyncSelect = (props) => (
    <SelectWrapper>
        <AsyncSelect
            {...props}
            styles={{
                control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderRadius: "var(--input-border-radius)",
                    borderColor: state.isFocused
                        ? "var(--input-focus-border-color)"
                        : "var(--input-border-color)",
                    boxShadow: state.isFocused
                        ? "var(--input-focus-box-shadow)"
                        : "none",
                    "&:hover": {
                        borderColor: "none",
                    },
                }),
            }}
        />
    </SelectWrapper>
);

/**
 *
 * @param {*} props any props that would be added to a div
 * @returns Wrapped Select wrapper (non-async) with a react select & some default styles
 */
const WrappedSelect = (props) => (
    <SelectWrapper>
        <Select
            {...props}
            styles={{
                control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderRadius: "var(--input-border-radius)",
                    borderColor: state.isFocused
                        ? "var(--input-focus-border-color)"
                        : "var(--input-border-color)",
                    boxShadow: state.isFocused
                        ? "var(--input-focus-box-shadow)"
                        : "none",
                    "&:hover": {
                        borderColor: "none",
                    },
                }),
            }}
        />
    </SelectWrapper>
);

const SelectWrapper = styled.div`
    div[class*="-placeholder"] {
        z-index: 0;
        white-space: nowrap;
        // Target sibling - which may sometimes have -Input
        & + div {
            z-index: 2;
            width: 100%;
            > div {
                width: 100%;
            }
        }
    }
    /* targeting input wrapper */
    div[class*="-control"] div div:not([class*="-placeholder"]) {
        grid-template-columns: min-content;
    }

    div[class*="-Input"] {
        z-index: 2;
        width: 100%;
        > div {
            width: 100%;
        }
    }

    div[class*="-menu"] {
        z-index: 4;
    }

    input {
        opacity: 1 !important;
        width: 100% !important;
        z-index: 3;
    }
`;

export { WrappedAsyncSelect as AsyncSelect, WrappedSelect as Select };
