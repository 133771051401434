import React, { useState, useEffect } from "react";
import { Input } from "reactstrap";
import { buildValidation } from "../../support/helpers";

function ValidatingInput({
    type,
    name,
    dataTest,
    placeholder,
    assembly,
    register,
    forceReadOnly,
    formFieldClasses = "",
    formFieldStyles = {},
    getValues,
    defaultValueOverride,
    disabled,
    ...props
}) {
    const assemblyItem = assembly.find((item) => item.id === name);
    if (!assemblyItem) return null;
    const {
        fieldInfo: {
            placeholder: infoPlaceholder,
            label,
            type: infoType,
            default: formValue,
            readonly,
        },
        listInfo,
    } = assemblyItem;
    const defaultValue = defaultValueOverride || formValue;
    const generatedPlaceholder =
        infoPlaceholder !== "text placeholder" ? infoPlaceholder : label;
    const { ref, ...registerField } = register(
        name,
        buildValidation(assembly, name, getValues)
    );
    const {
        formState: { errors },
    } = props;

    return (
        <Input
            className={`px-2 ${formFieldClasses}`}
            style={formFieldStyles}
            type={type || infoType || "text"}
            name={name}
            data-test={dataTest || name}
            placeholder={placeholder || generatedPlaceholder}
            defaultValue={defaultValue || undefined}
            readOnly={forceReadOnly || readonly === 1}
            disabled={
                (type === "select" && (forceReadOnly || readonly === 1)) ||
                disabled
            }
            innerRef={ref}
            invalid={errors?.[name] !== undefined}
            {...registerField}
        >
            {listInfo && type === "select" && (
                <>
                    <option value=""></option>
                    {listInfo.list.map((listItem, i) => (
                        <option key={`${name}-${i}`} value={listItem.value}>
                            {listItem.label}
                        </option>
                    ))}
                </>
            )}
        </Input>
    );
}

export default ValidatingInput;
