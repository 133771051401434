import { createSlice } from "@reduxjs/toolkit";

export const pageTypes = {
    index: "index",
    state: "states",
    niche: "nicheItems",
    nicheItem: "nicheItems",
    offmarket: "offmarket",
    nicheItem_development: "developments",
    development: "developments",
    listing: "listings",
    agent: "agents",
    agents: "brokerAgents",
};
export const pageDataTypes = Object.keys(pageTypes);

export const getIdByPageType = (pageType, data) => {
    if (pageType === "states") return data.code;
    if (pageType === "nicheItems" || pageType === "offmarket") return data.id;
    if (pageType === "developments") return data.developmentID;
    if (pageType === "listings") return data?.identifiers?.id;
    if (pageType === "agents")
        return data?.agentInfo?.agent_name?.more?.internalAgentID;
    return undefined;
};

const initialState = {
    pageHeaderInfo: {},
    agents: {},
    allAgents: {},
    states: {},
    nicheItems: {},
    offmarket: {},
    developments: {},
    listings: {},
    statesAgents: {},
};

const pagesSlice = createSlice({
    name: "pages",
    initialState,
    reducers: {
        addPage: (state, action) => {
            const { id, pageType, data: rawData } = action.payload;
            const data =
                pageType === "states"
                    ? {
                          ...rawData,
                          isServiced:
                              rawData?.nicheItemsPreviewInfo.length !== 0,
                      }
                    : rawData;
            if (pageType) {
                if (id) {
                    state[pageType][id] = data;
                } else {
                    state[pageType] = data; // some pages wont have an id (index, states agents...)
                }
            }
        },
        addAgentPage: (state, action) => {
            const { id, data } = action.payload;
            const {
                agent: { dataGroup, dataSet, ...agent },
                listingsPreview,
                gis,
            } = data;
            state.agents[id] = {
                data: agent,
                listingsPreview,
                gis,
            };
        },
        removePage: (state, action) => {
            const { id, pageType } = action.payload;
            if (pageType) {
                if (id) {
                    delete state[pageType][id];
                } else {
                    delete state[pageType]; // some pages wont have an id (index, states agents...)
                }
            }
        },
        setPageHeaderInfo: (state, action) => {
            state.pageHeaderInfo = action.payload;
        },
    },
});

// Extract the action creators object and the reducer
const { actions, reducer } = pagesSlice;
// Extract and export each action creator by name
export const { addPage, setPageHeaderInfo, addAgentPage, removePage } = actions;
// Export the reducer, either as a default or named export
export default reducer;
