import { useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { getBaseUrl, fetchOverlord } from "../support/helpers";
import { v4 as uuidv4 } from "uuid";

export const logout = async (sessionId, newSessionId) => {
    const response = await fetchOverlord(`${getBaseUrl()}/logout`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            sessionId,
            newSessionId,
        },
    });
    return response;
};

export const useUserLogout = (initialResults = null) => {
    const sessionID = useSelector((state) => state.root.userSession.sessionID);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState(initialResults);

    const execute = async (newSessionId = uuidv4()) => {
        try {
            setIsLoading(true);
            setError(null);
            const results = await logout(sessionID, newSessionId);
            setData(results);
            setIsLoading(false);
            return results;
        } catch (e) {
            setError(e);
            setIsLoading(false);
        }
    };

    return {
        isLoading,
        error,
        data,
        execute: useCallback(execute, [sessionID]), // to avoid infinite calls when inside a `useEffect`
        clear: () => setData(null),
    };
};
