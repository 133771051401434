import React from "react";
import Banner from "../Banner";
import { getConfigItem } from "../../support/helpers";

const MaintenanceBanner = () => {
    const showMaintenanceBanner =
        getConfigItem("REACT_APP_INCOMING_MAINTENANCE_MODE") || "";
    const [bannerText, bannerColor] = showMaintenanceBanner.split("|");

    if (!showMaintenanceBanner) return null;
    return (
        <Banner
            bannerColor={bannerColor || "primary"}
            bannerText={bannerText}
            dismissable={true}
            className="mb-0"
        />
    );
};

export default MaintenanceBanner;
