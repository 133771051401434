/*
 * ------------------------------------------------------------------------------------
 * DESCRIPTION:
 * ------------------------------------------------------------------------------------
 *       NoOptionsMessage component
 *
 * ------------------------------------------------------------------------------------
 * VERSION HISTORY: (NOTE: The version variables must be updated manually.)
 * ------------------------------------------------------------------------------------
 *                   Changed By
 *      Date         Initials    Version #  Sub-Version  Notes
 *      -----------  ----------  ---------  -----------  ------------------------------
 *      2022-11-21   BL          22325      1            Re-structured files and renamed to index.js
 *
 */
import React from "react";
import { components } from "react-select";

const NoOptionsMessage = ({ label, ...props }) => {
    const { NoOptionsMessage } = components;
    return (
        <NoOptionsMessage {...props}>
            <span>{label}</span>
        </NoOptionsMessage>
    );
};

export default NoOptionsMessage;
