/*
 * ------------------------------------------------------------------------------------
 * DESCRIPTION:
 * ------------------------------------------------------------------------------------
 *       Image component
 *
 * ------------------------------------------------------------------------------------
 * VERSION HISTORY: (NOTE: The version variables must be updated manually.)
 * ------------------------------------------------------------------------------------
 *                   Changed By
 *      Date         Initials    Version #  Sub-Version  Notes
 *      -----------  ----------  ---------  -----------  ------------------------------
 *      2022-11-16   BL          22320      1            Re-structured files and renamed to index.js
 *
 */
import React from "react";

const Image = ({ loading, onError, ...props }) => {
    return (
        <img
            {...props}
            loading={loading || "lazy"}
            onError={
                onError
                    ? onError
                    : (e) =>
                          (e.target.src = "/assets/no-image-found-360x260.png")
            }
        />
    );
};

export default Image;
