import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useCookies } from "react-cookie";
import { v4 as uuidv4 } from "uuid";
import { setSession } from "../reducers/rootReducer";

export const useNewSession = () => {
    const dispatch = useDispatch();
    const [{ sessionId }] = useCookies(["sessionId"]);

    const create = () => {
        const newSessionId = uuidv4();
        dispatch(setSession(newSessionId));
    };

    const get = () => sessionId;

    const update = (id) => dispatch(setSession(id));

    return {
        create: useCallback(create, [dispatch]),
        get: useCallback(get, [sessionId]),
        update: useCallback(update, [dispatch]),
    };
};
