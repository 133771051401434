import { useState, useCallback } from "react";
import { useSelector } from "react-redux";
import {
    getBaseUrl,
    buildQueryParams,
    fetchOverlord,
} from "../support/helpers";

export const searchLocations = async (
    searchValue,
    sessionId,
    options = {},
    baseUrl
) => {
    const { headers, ...restOptions } = options;
    const query = buildQueryParams(searchValue);
    const response = await fetchOverlord(
        `${baseUrl}/search/locations${query}`,
        {
            headers: {
                "Content-Type": "application/json",
                sessionId,
                ...headers,
            },
            ...restOptions,
        }
    );
    return response;
};

export const useSearchLocations = () => {
    const sessionID = useSelector((state) => state.root.userSession.sessionID);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState();

    const execute = async (searchValue, options = {}) => {
        try {
            setIsLoading(true);
            setError(null);
            const baseUrl = getBaseUrl();
            const locations = await searchLocations(
                searchValue,
                sessionID,
                options,
                baseUrl
            );
            setData(locations);
            if (error && error !== null) {
                setError(null); // resetting error
            }
            setIsLoading(false);
            return locations;
        } catch (e) {
            setError(e);
            setData(null);
            setIsLoading(false);
        }
    };

    return {
        isLoading,
        error,
        data,
        execute: useCallback(execute, [sessionID]), // to avoid infinite calls when inside a `useEffect`
        clear: () => setData(null),
    };
};
