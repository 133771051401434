import { useState, useCallback } from "react";
import { useSelector } from "react-redux";
import {
    getBaseUrl,
    buildQueryParams,
    fetchOverlord,
} from "../support/helpers";

export const getListings = async (
    sessionId,
    payload,
    isGis = false,
    options = {},
    baseUrl,
    shouldUseCache = false
) => {
    const { headers, ...restOptions } = options;
    const query = buildQueryParams(payload);
    const response = await fetchOverlord(
        `${baseUrl}/search/listings${
            isGis ? `/${shouldUseCache ? "cached" : "gis"}` : ""
        }${query}`,
        {
            headers: {
                "Content-Type": "application/json",
                sessionId,
                ...headers,
            },
            ...restOptions,
        }
    );
    return response;
};

export const splitGisRequests = async (
    sessionId,
    payload,
    chunkSize,
    isGis = false,
    options = {},
    baseUrl
) => {
    const { niche_item_ids } = payload?.filters;
    const chunks = [];
    for (let i = 0; i < niche_item_ids.length; i += chunkSize) {
        chunks.push(niche_item_ids.slice(i, i + chunkSize));
    }
    const promiseResults = await Promise.all(
        chunks.map((chunk) =>
            getListings(
                sessionId,
                {
                    filters: { ...payload.filters, niche_item_ids: chunk },
                    notFoundInCache: true,
                },
                isGis,
                options,
                baseUrl
            )
        )
    );
    const data = promiseResults.reduce(
        (previousValue, currentValue) => ({
            total_count: (previousValue.total_count +=
                currentValue.total_count),
            filters: {
                ...previousValue.filters,
                niche_item_ids: previousValue.filters.niche_item_ids.concat(
                    currentValue.filters.niche_item_ids
                ),
            },
            results: previousValue.results.concat(currentValue.results),
        }),
        promiseResults.shift()
    );
    return data;
};

export const useListings = (isGis = false) => {
    const sessionID = useSelector((state) => state.root.userSession.sessionID);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState();

    const execute = async (payload, splitRequests = false, chunkSize = 10) => {
        try {
            setIsLoading(true);
            const { niche_item_ids } = payload?.filters;
            const shouldSplit =
                splitRequests &&
                chunkSize > 0 &&
                Array.isArray(niche_item_ids) &&
                niche_item_ids.length > chunkSize;
            const shouldUseCache = isGis && !splitRequests;
            const options = {};
            const baseUrl = getBaseUrl();
            let data;
            if (shouldSplit) {
                data = await splitGisRequests(
                    sessionID,
                    payload,
                    chunkSize,
                    isGis,
                    options,
                    baseUrl
                );
            } else {
                data = await getListings(
                    sessionID,
                    payload,
                    isGis,
                    options,
                    baseUrl,
                    shouldUseCache
                );
            }
            setData(data);
            if (error) {
                setError(null); // resetting error
            }
            setIsLoading(false);
            return data;
        } catch (e) {
            setError(e);
            setIsLoading(false);
            throw e;
        }
    };

    return {
        isLoading,
        error,
        data,
        execute: useCallback(execute, [error, sessionID]), // to avoid infinite calls when inside a `useEffect`
        clear: () => setData(null),
    };
};
