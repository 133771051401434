import React, { useState, useEffect } from "react";
import ReactToggleButton from "react-toggle-button";
import { FaCheck, FaTimes } from "react-icons/fa";
import { omit } from "../../support/helpers";

// TODO: replced this with react-toggle
// https://github.com/aaronshaf/react-toggle/blob/master/src/component/index.js

const ToggleButton = (props) => {
    const { className, value, onToggle: onToggleFunc, disabled } = props;
    const [toggleValue, setToggleValue] = useState(value);

    const thumbStyle = {
        color: toggleValue ? "var(--bs-primary)" : "var(--bs-black)",
        width: "30px",
        height: "30px",
        left: "-10px",
        display: "flex",
        alignSelf: "center",
        alignItems: "center",
        justifyContent: "center",
    };

    const colors = {
        activeThumb: { base: "var(--bs-primary)", hover: "var(--bs-primary)" },
        inactiveThumb: {
            base: "var(--bs-white)",
            hover: disabled ? "var(--bs-white)" : "var(--bs-light-gray)",
        },
        active: { base: "rgb(65, 66, 68)" },
        inactive: { base: "rgb(65, 66, 68)" },
    };

    useEffect(() => {
        if (value !== toggleValue) {
            setToggleValue(value);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    return (
        <div className={className} style={{ minHeight: "35px" }}>
            <ReactToggleButton
                inactiveLabel=""
                activeLabel=""
                {...omit(props, ["className", "containerStyle"])}
                containerStyle={{
                    opacity: disabled ? 0.5 : 1,
                    cursor: disabled ? "default" : "pointer",
                    ...props?.containerStyle,
                }}
                value={toggleValue}
                colors={colors}
                thumbStyle={thumbStyle}
                thumbAnimateRange={[-10, 36]}
                thumbIcon={toggleValue ? <FaCheck /> : <FaTimes />}
                onToggle={(value) => {
                    if (!disabled) {
                        setToggleValue(!value);
                        onToggleFunc(value);
                    }
                }}
            />
        </div>
    );
};

export default ToggleButton;
