import React, { useCallback } from "react";
import LifeStylePost from "./Post";
import Loading from "../../../Loading";
import { useWordpress } from "../../../../hooks/useWordpress";
import { Alert, Button } from "reactstrap";

const Lifestyles = ({ numberOfPosts = 2, categories }) => {
    const { execute, isLoading, data: posts, error, clear } = useWordpress();

    const getPosts = useCallback(() => {
        execute(`posts?per_page=${numberOfPosts}&categories=${categories}`);
    }, [execute, numberOfPosts, categories]);

    if (!posts && !isLoading && !!categories) {
        getPosts();
    }

    if (!categories) return null;
    return (
        <>
            {isLoading && !posts && <Loading loadingLabel="Fetching Posts" />}
            <div className="row">
                {posts &&
                    !isLoading &&
                    posts.map((post, i) => (
                        <div
                            key={`lifestyle-post-${i}`}
                            className="col-md-6 mb-3 mb-md-0"
                        >
                            <LifeStylePost
                                key={`lifestyle-post-${i}`}
                                post={post}
                            />
                        </div>
                    ))}
            </div>
            {(error || (!posts && !isLoading)) && (
                <Alert color="primary">
                    <div>
                        Unable to load posts.
                        <Button
                            color="link"
                            className="p-0"
                            onClick={() => {
                                clear();
                                getPosts();
                            }}
                        >
                            Click here to try again.
                        </Button>
                    </div>
                </Alert>
            )}
        </>
    );
};

export default Lifestyles;
