import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAssembliesByGroup } from "../../support/helpers";
import { useGetAssembly } from "../../hooks/useAssembly";
import { setAssemblies } from "../../reducers/rootReducer";

const AssemblyProvider = ({
    children,
    name,
    group,
    ignoreCache,
    transformData,
}) => {
    const dispatch = useDispatch();
    const { execute, isLoading } = useGetAssembly();
    const sessionID = useSelector((state) => state.root.userSession.sessionID);
    const assemblies = useSelector((state) => state.root.assemblies);
    const assembly = getAssembliesByGroup(assemblies[group], name);

    const getAssembly = async (
        gGroup = group,
        gName = name,
        gIgnoreCache = ignoreCache,
        params = {}
    ) => {
        const response = await execute(sessionID, gName, gIgnoreCache, params);
        if (transformData) response.data = transformData();
        dispatch(setAssemblies({ [gGroup]: response }));
    };

    useEffect(() => {
        if (!assembly) {
            const runGetAssembly = async () => {
                await getAssembly(group, name, ignoreCache);
            };
            runGetAssembly();
        }
    }, [assembly, sessionID, execute, dispatch]);

    return children(assembly, isLoading, getAssembly);
};

export default AssemblyProvider;
