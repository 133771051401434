import React from "react";
import { Button, Spinner } from "reactstrap";

const darkButtons = ["primary", "danger", "info", "success"];

/**
 * Renders a button that will display a spinner when it is in a loading state.
 *
 * @param {Object} isLoading: A boolean indicating whether the button is in a loading state. Defaults to false.
 * @param {Object} color: The color of the button. Defaults to "primary".
 * @param {Object} children: The content to display inside the button.
 * @param {Object} props: Additional props to pass to the Button component.
 * @return {JSX.Element} The rendered indeterminate button component.
 */
export const IndeterminateButton = ({
    isLoading = false,
    color = "primary",
    children,
    ...props
}) => {
    const iconColor = darkButtons.indexOf(color) > -1 ? "white" : "dark";
    const buttonIcon = isLoading ? (
        <Spinner
            size="sm"
            color={iconColor}
            className="me-2"
            data-testid="spinner"
        >
            {""}
        </Spinner>
    ) : undefined;
    return (
        <Button disabled={isLoading} color={color} {...props}>
            {buttonIcon}
            <span className="mx-auto">{children}</span>
        </Button>
    );
};

export default IndeterminateButton;
