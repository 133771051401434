import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "reactstrap";
import { FaHeart, FaRegHeart } from "react-icons/fa";
import { publish } from "pubsub-js";
import { useUserProperties } from "../../hooks/useUserProperties";
import useToggle from "../../hooks/useToggle";
import useTrackEvent from "../../hooks/useTrackEvent";
import {
    addSavedProperty,
    removeSavedProperty,
    setUserInfo,
} from "../../reducers/rootReducer";
import { toast } from "react-toastify";
import { capitalizeFirstLetter } from "../../support/helpers";

const SaveProperty = ({
    children,
    listingId,
    heartClassName = "",
    location,
    ...props
}) => {
    const dispatch = useDispatch();
    const isSaved = useSelector(
        (state) => state.root.savedProperties.indexOf(listingId) > -1
    );
    const sessionId = useSelector((state) => state.root.userSession.sessionID);
    const userID = useSelector((state) => state.root.userSession.userID);
    const isLoggedIn = useSelector((state) => state.root.isLoggedIn);
    const isMobile = useSelector((state) => state.root.isMobile);
    const { execute, data, error, clear } = useUserProperties();
    const [waitingForLogin, toggleWaitingForLogin] = useToggle(false);
    const { trackEvent, trackGTM } = useTrackEvent();

    const handleSaveProperty = () => {
        if (isLoggedIn) {
            execute(
                userID,
                {
                    method: isSaved ? "DELETE" : "POST",
                    headers: {
                        "Content-Type": "application/json",
                        sessionId,
                    },
                },
                listingId
            );

            trackGTM({
                event: `buttonClick`,
                action: "click",
                type: "Save Property",
                category: "user_action",
                value: isSaved ? "delete" : "save",
                isLoggedIn,
                location,
                listingId,
            });
            trackEvent("SAVE-LISTING", {
                SOURCE_INFO: {
                    eventAction: "click",
                    eventLabel: `Logged ${
                        isLoggedIn ? "In" : "Out"
                    } - ${location}`,
                    eventValue: listingId,
                },
            });
            dispatch(
                !isSaved
                    ? addSavedProperty(listingId)
                    : removeSavedProperty(listingId)
            );
        } else {
            toggleWaitingForLogin();
            if (isMobile) {
                publish("TOGGLE_MOBILE_DRAWER");
            } else {
                publish("TOGGLE_ACCOUNT_DRAWER");
            }
        }
    };

    useEffect(() => {
        if (data) {
            if (data?.message?.limitExceeded) {
                toast.error(
                    `${capitalizeFirstLetter(data?.message?.limitExceeded)}.`
                );
                // Add/Remove the saved property if they reached the limit.
                dispatch(
                    !isSaved
                        ? addSavedProperty(listingId)
                        : removeSavedProperty(listingId)
                );
            } else {
                toast.success(
                    <div className="d-flex justify-content-between">
                        <div>
                            {isSaved
                                ? "Property Saved!"
                                : "Saved Property Removed."}
                        </div>
                        <a href="/account/properties">See All</a>
                    </div>,
                    { icon: false }
                );
            }
            clear();
        }
    }, [data]);

    useEffect(() => {
        if (error) {
            const savingOrRemoving = isSaved ? "saving" : "removing";
            toast.error(
                `Sorry! We we ran into an issue ${savingOrRemoving} this property.`
            );
            // Add/Remove the property if there was an issue.
            dispatch(
                !isSaved
                    ? addSavedProperty(listingId)
                    : removeSavedProperty(listingId)
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

    useEffect(() => {
        if (data?.sessionID && isLoggedIn) {
            dispatch(setUserInfo(data));
        } else if (!isLoggedIn) clear();
    }, [data, isLoggedIn]);

    // This effect waits till the user is logged in to attempt the save again.
    useEffect(() => {
        if (waitingForLogin && isLoggedIn) {
            toggleWaitingForLogin();
            handleSaveProperty();
        }
    }, [isLoggedIn]);

    return (
        <Button
            id={`SaveProperty${listingId}`}
            {...props}
            onClick={() => handleSaveProperty()}
        >
            {isSaved ? (
                <FaHeart
                    style={{
                        filter: "drop-shadow(rgba(0, 0, 0, 0.3) 0px 2px 4px)",
                    }}
                />
            ) : (
                <FaRegHeart
                    className={heartClassName}
                    style={{
                        filter: "drop-shadow(rgba(0, 0, 0, 0.3) 0px 2px 4px)",
                    }}
                />
            )}

            {children && children(isSaved)}
        </Button>
    );
};

export default SaveProperty;
