import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    selectedMarker: undefined,
    activeMarker: {},
};

export const resetMap = (dispatch) => {
    dispatch(removeMarkers());
    dispatch(setGisDefault());
};

export const filterMarkers = (markers) =>
    (markers || []).filter((marker) => marker.longitude && marker.latitude);

const mapSlice = createSlice({
    name: "map",
    initialState,
    reducers: {
        setMap: (state, action) => {
            state.map = action.payload.map;
            state.maps = action.payload.maps;
        },
        setMarkers: (state, action) => {
            state.markers = action.payload;
        },
        removeMarkers: (state) => {
            state.markers = [];
        },
        setGisDefault: (state, action) => {
            state.gisDefault = action.payload;
        },
        setActiveMarker: (state, action) => {
            state.activeMarker = action.payload;
        },
        setMapHeight: (state, action) => {
            state.mapHeight = action.payload;
        },
        setSelectedMarker: (state, action) => {
            state.selectedMarker = action.payload;
        },
    },
});

// Extract the action creators object and the reducer
const { actions, reducer } = mapSlice;
// Extract and export each action creator by name
export const {
    setActiveMarker,
    setMap,
    setMarkers,
    removeMarkers,
    setGisDefault,
    setMapHeight,
    setSelectedMarker,
} = actions;
// Export the reducer, either as a default or named export
export default reducer;
