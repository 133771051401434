import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Row, Col, Form, FormGroup, Alert } from "reactstrap";
import { useForm } from "react-hook-form";
import ValidatingField from "../ValidatingField";
import { getAssembliesByGroup } from "../../support/helpers";
import { useUser } from "../../hooks/user";
import { useNewSession } from "../../hooks/useNewSession";
import { useGetStyling } from "../../hooks/useStyling";
import { useGetAssembly } from "../../hooks/useAssembly";
import IndeterminateButton from "../IndeterminateButton";
import { setAssemblies, setStyling } from "../../reducers/rootReducer";
import Curtain from "../Curtain";
import { encode } from "base-64";

const assemblyName = "CHANGEPW";
const assemblyGroup = "CHANGEPW";

const ChangePassword = () => {
    const dispatch = useDispatch();
    const sessionID = useSelector((state) => state.root.userSession.sessionID);
    const assemblies = useSelector((state) => state.root.assemblies);
    const assembly = getAssembliesByGroup(
        assemblies?.["CHANGEPW"],
        assemblyGroup
    );
    const { execute: getAssembly, isLoading: assemblyIsLoading } =
        useGetAssembly();
    const {
        execute,
        isLoading: userIsLoading,
        data: userData,
        error: userError,
    } = useUser();
    const [alertData, setAlertData] = useState(undefined);
    const { create: createNewSession } = useNewSession();
    const { execute: getStyling, isLoading: isStylingLoading } =
        useGetStyling();
    const registerForm = useForm();
    const { handleSubmit, setError, reset } = registerForm;
    const isLoading = userIsLoading || isStylingLoading;

    useEffect(() => {
        if (!assembly && !assemblyIsLoading) {
            const runGetAssembly = async () => {
                dispatch(
                    setAssemblies({
                        CHANGEPW: await getAssembly(sessionID, assemblyName),
                    })
                );
            };
            runGetAssembly();
        }
        // We only want this to run on first load so we do not want to provide any dependencies
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSubmit = (data) => {
        const { password_current, password, password_verify } = data;
        execute("USER_ID/change-password/confirm", {
            method: "POST",
            body: JSON.stringify({
                current: encode(password_current),
                updated: encode(password),
                verify: encode(password_verify),
            }),
        });
    };

    useEffect(() => {
        if (userData !== null && !userError) {
            // User data is returned as a 204 and will change from null to undefined
            let fetchData;
            if (userData?.entryDefects) {
                userData.entryDefects.forEach((defect) => {
                    const name = Object.keys(defect)[0];
                    setError(name, { message: defect[name] });
                });
            } else {
                setAlertData({
                    color: "success",
                    alertText: "Your password has been successfully updated.",
                });
                reset();
                if (userData?.loginRequired) {
                    createNewSession();
                } else {
                    fetchData = async () => {
                        dispatch(setStyling(await getStyling(sessionID)));
                    };
                }
            }
            if (fetchData) fetchData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userData]);

    useEffect(() => {
        if (userError && !alertData) {
            setAlertData({
                color: "danger",
                alertText: (
                    <>
                        Sorry, we had an issue updating your password. Please{" "}
                        <Button
                            className="p-0"
                            color="link"
                            onClick={() => handleSubmit(onSubmit)}
                        >
                            click here to try again.
                        </Button>
                    </>
                ),
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userError]);

    return (
        <Curtain show={assemblyIsLoading} withLoading>
            <Alert
                color={alertData?.color}
                isOpen={alertData !== undefined}
                toggle={() => setAlertData(undefined)}
            >
                {alertData?.alertText}
            </Alert>
            <Form
                className="w-100 px-3 px-md-0"
                onSubmit={handleSubmit(onSubmit)}
            >
                <Row>
                    <Col>
                        <ValidatingField
                            name="password_current"
                            type="password"
                            assembly={assembly}
                            {...registerForm}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ValidatingField
                            name="password"
                            type="password"
                            assembly={assembly}
                            {...registerForm}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ValidatingField
                            name="password_verify"
                            type="password"
                            assembly={assembly}
                            {...registerForm}
                        />
                    </Col>
                </Row>
                <FormGroup className="d-flex">
                    <IndeterminateButton
                        type="submit"
                        className="btn btn-primary ms-auto"
                        value="Verify"
                        isLoading={isLoading}
                    >
                        Update Password
                    </IndeterminateButton>
                </FormGroup>
            </Form>
        </Curtain>
    );
};

export default ChangePassword;
