import React, { useEffect, useRef } from "react";
import styled from "styled-components";

const MapPopOver = ({ stateData }) => {
    const popOverElement = useRef(null);

    const setElementPosition = (e) => {
        if (popOverElement !== null && popOverElement.current) {
            popOverElement.current.style.left = e.pageX + "px";
            popOverElement.current.style.top =
                e.pageY - popOverElement.current.offsetHeight - 50 + "px";
        }
    };

    useEffect(() => {
        if (popOverElement !== null && stateData) {
            document.addEventListener("mousemove", setElementPosition);
        }
        return () => {
            document.removeEventListener("mousemove", setElementPosition);
        };
    }, [popOverElement, stateData]);

    if (!stateData) return null;
    return (
        <>
            <StateHover
                id="stateHover"
                className="border border-0 rounded p-2 bg-white fw-bold position-absolute"
                ref={popOverElement}
            >
                <div className="tertiary">{stateData?.stateName}</div>
                {stateData?.isStateServed ? (
                    <>
                        <div>{stateData?.listingsInfo}</div>
                        <div>{stateData?.nicheItemsInfo}</div>
                    </>
                ) : (
                    <div>{stateData?.stateStatus}</div>
                )}
                <div className="d-flex justify-content-center">
                    <BulletWrapper className="bg-white">
                        <Bullet className="bg-tertiary" />
                    </BulletWrapper>
                </div>
            </StateHover>
        </>
    );
};

const StateHover = styled.div`
    width: 200px;
    transform: translate(-50%, -50%);
    z-index: 9999999999;
`;

const BulletWrapper = styled.div`
    border-radius: 50%;
    padding-top: 7px;
    margin-bottom: -17px;
`;

const Bullet = styled.div`
    height: 15px;
    width: 15px;
    margin: 5px;
    border-radius: 50%;
`;

export default MapPopOver;
