import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import parseUrl from "../support/searchUrlParser";

const Redirector = ({ children }) => {
    const { pathname, search } = useLocation();
    const history = useHistory();

    useEffect(() => {
        const url = parseUrl(`${pathname}${search}`, window.location.origin);
        if (url && (url.search !== search || pathname !== url.pathname)) {
            history.push({ pathname: url.pathname, search: url.search });
        }
    }, []);

    return children;
};

export default Redirector;
