import { useState, useCallback } from "react";
import { getBaseUrl, fetchOverlord } from "../support/helpers";
import { encode } from "base-64";

export const login = async (sessionId, username, password) => {
    const response = await fetchOverlord(`${getBaseUrl()}/login`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            authorization: `Basic ${encode(`${username}:${password}`)}`,
            sessionId,
        },
    });
    return response;
};

export const useUserLogin = (initialResults = null) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState(initialResults);

    const execute = async (session, username, password) => {
        try {
            setIsLoading(true);
            const results = await login(session, username, password);
            setData(results);
            if (error && error !== null) {
                setError(null); // resetting error
            }
            setIsLoading(false);
            return results;
        } catch (e) {
            setError(e);
            setData(null);
            setIsLoading(false);
        }
    };

    return {
        isLoading,
        error,
        data,
        execute: useCallback(execute, [error]), // to avoid infinite calls when inside a `useEffect`
        clear: () => setData(null),
    };
};
