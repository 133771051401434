import React, { useState } from "react";
import useTrackEvent from "../../../hooks/useTrackEvent";

const USAState = ({
    stateKey,
    stateName,
    dimensions,
    state,
    href,
    styles,
    hoverStyles,
    onClickState,
    setActiveState,
    isExcluded,
}) => {
    const [isHovered, setHovered] = useState(false);
    const { trackGTM } = useTrackEvent();

    const handleClick = (e) => {
        e.preventDefault();
        onClickState();

        trackGTM({
            event: `usaMap`,
            action: "click",
            type: "navigate",
            stateCode: stateKey,
            state: stateName,
        });
    };

    const style =
        onClickState && !isExcluded
            ? {
                  cursor: "pointer",
                  ...(isHovered ? hoverStyles : styles),
              }
            : {
                  cursor: "default",
                  ...styles,
              };

    return (
        <a
            href={href}
            xlink={href}
            data-test={`usa-map-state-${stateName
                .toString()
                .replace(/ /g, "-")
                .toLowerCase()}`}
        >
            <path
                d={dimensions}
                data-name={state}
                className={`${state} state state-path`}
                style={style}
                onClick={handleClick}
                onMouseEnter={() => {
                    if (isExcluded) return;
                    setHovered(true);
                    setActiveState(stateKey);
                }}
                onMouseLeave={() => {
                    if (isExcluded) return;
                    setHovered(false);
                    setActiveState(undefined);
                }}
            >
                <title>{stateName}</title>
            </path>
        </a>
    );
};

export default USAState;
