import React, { useState, useEffect, Component } from "react";
import { useLocation } from "react-router-dom";
import { PageError } from "../Pages";

/**
 * NEW: The error boundary has a function component wrapper.
 */
const PageErrorBoundry = ({ children }) => {
    const [hasError, setHasError] = useState(false);
    const location = useLocation();
    useEffect(() => {
        if (hasError) {
            setHasError(false);
        }
    }, [location.key]);
    return (
        <ErrorBoundaryInner hasError={hasError} setHasError={setHasError}>
            {children}
        </ErrorBoundaryInner>
    );
};

class ErrorBoundaryInner extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(_error) {
        return { hasError: true };
    }

    componentDidUpdate(prevProps, _previousState) {
        if (!this.props.hasError && prevProps.hasError) {
            this.setState({ hasError: false });
        }
    }

    componentDidCatch(error, errorInfo) {
        try {
            let baseUrl = "";
            if (location.origin.includes("localhost"))
                baseUrl = "http://localhost:3006";
            fetch(`${baseUrl}/logJsError`, {
                method: "POST",
                body: JSON.stringify({
                    message: error.toString(),
                    errorInfo: errorInfo,
                }),
                headers: {
                    "Content-Type": "application/json",
                },
            });
        } catch (e) {
            console.log("unable to reach app error logger");
        }
        // TODO: possibly fire off a monitoring event
        this.props.setHasError(true);
    }

    render() {
        return this.state.hasError ? <PageError /> : this.props.children;
    }
}

export default PageErrorBoundry;
