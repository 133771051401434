import { useState, useCallback } from "react";
import { useSelector } from "react-redux";
import {
    getBaseUrl,
    buildQueryParams,
    fetchOverlord,
} from "../support/helpers";

export const getSearchBox = async (
    searchbox,
    include,
    stateId,
    sessionId,
    options
) => {
    const query = buildQueryParams({ searchbox, include, stateId });
    const response = await fetchOverlord(
        `${getBaseUrl()}/search/matches${query}`,
        {
            headers: {
                "Content-Type": "application/json",
                sessionId,
                ...options?.headers,
            },
            ...options,
        }
    );
    return response;
};

export const useSearchbox = (initialResults = null) => {
    const sessionID = useSelector((state) => state.root.userSession.sessionID);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState(initialResults);

    const execute = async (
        searchbox,
        stateId,
        include = "cities,zips",
        options = {}
    ) => {
        try {
            setIsLoading(true);
            setError(null);
            const results = await getSearchBox(
                searchbox,
                include,
                stateId,
                sessionID,
                options
            );
            setData(results);
            setIsLoading(false);
            return results;
        } catch (e) {
            setError(e);
            setIsLoading(false);
            throw e;
        }
    };

    return {
        isLoading,
        error,
        data,
        execute: useCallback(execute, [sessionID]), // to avoid infinite calls when inside a `useEffect`
        clear: () => setData(null),
    };
};
