import React from "react";
import { useSelector } from "react-redux";
import Desktop from "./Desktop";
import Mobile from "./Mobile";
import AdminBanner from "./AdminBanner";
import MaintenanceBanner from "./MaintenanceBanner";
import { matchPath, useLocation } from "react-router-dom";
import { getPageType } from "../../support/helpers";

const Header = () => {
    const styling = useSelector((state) => state.root.styling);
    const { pathname } = useLocation();
    const { params } = matchPath(pathname, {
        path: "/:state?/:niche?/:three?",
    });
    const isHomePage = getPageType(params, "index");

    return (
        <>
            <MaintenanceBanner />
            <AdminBanner />
            <div
                id="header-container"
                className={`w-100 py-2 header bg-white ${
                    !isHomePage ? "shadow-sm" : ""
                }`}
            >
                {styling && (
                    <>
                        <div className="d-lg-none">
                            <Mobile
                                styling={styling}
                                navItems={styling?.microNav?.header}
                            />
                        </div>
                        <div className="d-none d-lg-block">
                            <Desktop
                                styling={styling}
                                navItems={styling?.microNav?.header}
                            />
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default Header;
