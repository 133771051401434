import React from "react";
import styled from "styled-components";

const Hero = ({
    children,
    className,
    path,
    height = "100%",
    position = "center center",
}) => (
    <div className={`${className} position-relative`} data-test="hero">
        <HeroImage
            className="w-100 position-absolute"
            path={path}
            height={height}
            position={position}
        />
        {children}
    </div>
);

const HeroImage = styled.div`
    background: ${(props) =>
        `transparent url(${props.path}) no-repeat ${props.position}`};
    background-size: cover;
    height: ${(props) => props.height};
`;

export default Hero;
