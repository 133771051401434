import React from "react";
import useToggle from "../../../hooks/useToggle";
import Update from "./Update";
import Verify from "./Verify";

const UpdateEmail = ({ children, isRegistering }) => {
    const [isUpdating, toggleIsUpdating] = useToggle(false);
    const [isVerifying, toggleIsVerifying] = useToggle(false);

    return (
        <>
            {!isUpdating &&
                !isVerifying &&
                children({
                    toggleIsUpdating: toggleIsUpdating,
                    toggleIsVerifying: toggleIsVerifying,
                })}
            {isUpdating && (
                <Update
                    cancel={() => toggleIsUpdating(false)}
                    isRegistering
                    verify={() => {
                        toggleIsUpdating();
                        if (!isRegistering) {
                            toggleIsVerifying();
                        }
                    }}
                />
            )}
            {isVerifying && (
                <Verify
                    cancel={() => toggleIsVerifying()}
                    complete={() => toggleIsVerifying()}
                />
            )}
        </>
    );
};

export default UpdateEmail;
