import React from "react";
import iconPath from "../../assets/icons.json";

/**
 * Renders an SVG icon with the specified icon, size, viewBox, and additional props.
 *
 * @param {string} icon - The name of the icon to render.
 * @param {number} size - The size of the icon in pixels.
 * @param {string} viewBox - The viewBox attribute of the SVG element.
 * @param {Object} style - Additional styles to apply to the SVG element.
 * @param {...Object} props.props - Additional props to pass to the SVG element.
 * @return {JSX.Element} The rendered SVG icon.
 */
const Icon = ({ icon, size = 16, viewBox = "0 0 24 24", style, ...props }) => {
    const svgStyle = {
        display: "inline-block",
        verticalAlign: "middle",
        ...style,
    };

    return (
        <svg
            viewBox={viewBox}
            width={`${size}px`}
            height={`${size}px`}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            stroke="currentColor"
            fill="currentColor"
            strokeWidth="0"
            style={svgStyle}
            {...props}
        >
            <path d={iconPath[icon]} />
        </svg>
    );
};

export default Icon;
