import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Row, Col, Form, FormGroup, Alert } from "reactstrap";
import { useForm } from "react-hook-form";
import ValidatingField from "../../ValidatingField";
import ReCaptchaFormItem from "../../ReCaptchaFormItem";
import { useUser } from "../../../hooks/user";
import IndeterminateButton from "../../IndeterminateButton";
import {
    setAssemblies,
    setIsLoggedIn,
    setUserInfoByKey,
} from "../../../reducers/rootReducer";
import AssemblyProvider from "../../AssemblyProvider";

const Create = ({ nextStep, setUsername }) => {
    const dispatch = useDispatch();
    const registerForm = useForm({ mode: "onBlur" }); // Must set mode to all for it to validate on blur of all fields. This is to be able to enable/disable the submit button
    const {
        handleSubmit,
        setError,
        formState: { isValid, isSubmitting },
    } = registerForm;
    const { execute, isLoading, error } = useUser(null, 1);

    const onSubmit = async (data) => {
        // POSTING to /user
        if (!isSubmitting) {
            const response = await execute(undefined, {
                method: "POST",
                body: JSON.stringify(data),
            });
            if (response?.entryDefects) {
                response.entryDefects.forEach((defect) => {
                    const name = Object.keys(defect)[0];
                    setError(name, { message: defect[name] });
                });
            } else if (response?.assembly) {
                dispatch(
                    setAssemblies({
                        "SIGNUP-PW_ESTABLISH_REQUEST": response.assembly,
                    })
                );
                // setting the user a logged in to make sure the verify
                // step shows up if they close the drawer
                dispatch(
                    setUserInfoByKey({
                        key: "firstName",
                        value: data.first_name,
                    })
                );
                setUsername(data.username);
                nextStep(false, () => dispatch(setIsLoggedIn(true)));
            }
        }
    };

    return (
        <AssemblyProvider group="USER_ACCOUNT" name="SIGNUP">
            {(assembly) => (
                <>
                    {error && (
                        <Alert color="warning">
                            Whoops, we had an issue creating your account.
                            Please click Register to try again or come back
                            later to try again. If you continue to have issues,
                            please <Link to="/info/contact-us">contact us</Link>
                            .
                        </Alert>
                    )}
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <FormGroup>Create an account.</FormGroup>
                        <Row>
                            <Col sm>
                                <ValidatingField
                                    name="first_name"
                                    assembly={assembly}
                                    {...registerForm}
                                />
                            </Col>
                            <Col sm>
                                <ValidatingField
                                    name="last_name"
                                    assembly={assembly}
                                    {...registerForm}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <ValidatingField
                                    name="email"
                                    assembly={assembly}
                                    {...registerForm}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <ValidatingField
                                    name="email_verify"
                                    assembly={assembly}
                                    {...registerForm}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <ValidatingField
                                    name="username"
                                    dataTest="username-reg"
                                    assembly={assembly}
                                    {...registerForm}
                                />
                            </Col>
                        </Row>
                        <Row className="align-items-center">
                            <div className="col-12 col-md-6">
                                <FormGroup>
                                    <ReCaptchaFormItem {...registerForm} />
                                </FormGroup>
                            </div>
                            <div className="col-12 col-md-6">
                                <FormGroup className="d-flex">
                                    <IndeterminateButton
                                        type="submit"
                                        className="btn btn-primary ms-auto"
                                        value="Create Account"
                                        disabled={!isValid}
                                        isLoading={isLoading || isSubmitting}
                                    >
                                        Register
                                    </IndeterminateButton>
                                </FormGroup>
                            </div>
                        </Row>
                    </Form>
                </>
            )}
        </AssemblyProvider>
    );
};

export default Create;
