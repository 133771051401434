import { useLocation } from "react-router-dom";

/**
 * Reimplements the useLocation hook to include state information passed from
 * the server side render.
 */
export const useNacLocation = () => {
    const location = useLocation();
    return {
        ...location,
        state:
            location?.state ||
            (typeof window !== "undefined" &&
                window !== null &&
                window?.locationState),
    };
};
