import loadable from "@loadable/component";
import NotFound from "./components/NotFound";
import { matchPath } from "react-router-dom";

const Home = loadable(() =>
    import(/* webpackChunkName: "HomePage" */ "./components/Pages/Home")
);
const Agents = loadable(() =>
    import(/* webpackChunkName: "AgentsPage" */ "./components/Pages/Agents")
);

const Account = loadable(() =>
    import(
        /* webpackChunkName: "AccountPage" */ "./components/Pages/Account/Account"
    )
);
const Search = loadable(() =>
    import(
        /* webpackChunkName: "SearchPage" */ "./components/Pages/Search/Search"
    )
);
const MapSidebar = loadable(() =>
    import(
        /* webpackChunkName: "MapSidebarPage" */ "./components/Pages/MapSidebar"
    )
);

const Routes = [
    {
        path: "/",
        exact: true,
        component: Home,
        pageType: "index",
    },
    {
        path: "/agents",
        exact: true,
        component: Agents,
        pageType: "agents",
    },
    {
        path: "/account",
        component: Account,
    },
    {
        path: "/search",
        exact: true,
        component: Search,
    },
    // We want to redirect to the wordpress site any time we hit a path that starts with /info
    {
        path: "/info*",
        component: (things) => {
            const {
                location: { pathname },
            } = things;
            const origin =
                window.location.hostname === "localhost"
                    ? "https://testing4.lakehomes.com"
                    : window.location.origin;
            window.location.href = `${origin}${pathname}`;
            return null;
        },
    },
    {
        path: `/:state?/:niche?/:three?`,
        component: MapSidebar,
        pageType: "mapSidebarRoutes",
    },
    {
        path: "*",
        exact: true,
        component: NotFound,
    },
];

export const mapSidebarRoutes = [
    {
        path: `/:state/offmarket/:niche`,
        exact: true,
        pageType: "nicheItem",
    },
    {
        path: `/:state/:niche/:three`,
        pageType: "listing|agent|nicheItem_development",
    },
    {
        path: "/:state/:niche",
        exact: true,
        pageType: "nicheItem",
    },
    {
        path: "/:state",
        exact: true,
        pageType: "state",
    },
];

/**
 * Finds a route that matches the given path and returns its associated pageType.
 * @param {string} path - The URL path to match with a route.
 * @returns {string} The pageType associated with the matching route.
 */
export const matchRoute = (path) => {
    let route = Routes.find((route) => matchPath(path, route));
    if (route?.pageType === "mapSidebarRoutes") {
        route = mapSidebarRoutes.find((route) => matchPath(path, route));
    }
    return route?.pageType;
};

export default Routes;
