import React from "react";
import { getConfigItem } from "../../support/helpers";
import ComingSoonModal from "./ComingSoonModal";

/**
 * Displays a "coming soon" modal if the REACT_APP_COMING_SOON env variable is set.
 * The purpose of this is for beta testing in production.
 * @param {*} children
 */
export default ({ children }) => {
    const comingSoon = getConfigItem("REACT_APP_COMING_SOON");

    return (
        <>
            {comingSoon ? (
                <ComingSoonModal comingSoonValue={comingSoon}>
                    {children}
                </ComingSoonModal>
            ) : (
                children
            )}
        </>
    );
};
