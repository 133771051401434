/*
 * ------------------------------------------------------------------------------------
 * DESCRIPTION:
 * ------------------------------------------------------------------------------------
 *      Footer component
 *
 * ------------------------------------------------------------------------------------
 * VERSION HISTORY: (NOTE: The version variables must be updated manually.)
 * ------------------------------------------------------------------------------------
 *                   Changed By
 *      Date         Initials    Version #  Sub-Version  Notes
 *      -----------  ----------  ---------  -----------  ------------------------------
 *      2022-11-14   BL          22318      1            Re-structured files and renamed to index.js
 *
 */
import React from "react";
import { useQueryParam, BooleanParam } from "use-query-params";
import MobileFooter from "./MobileFooter";
import DesktopFooter from "./DesktopFooter";

/**
 * Website Footer.
 */
const Footer = () => {
    const [isMapVisible] = useQueryParam("mapVisible", BooleanParam);

    return (
        <div
            className={`footer footer-bg-color w-100 mt-auto p-1 p-lg-5 ${
                isMapVisible ? "d-none" : ""
            }`}
            id="footer-container"
        >
            <div id="top-of-footer"></div>
            <div className="d-lg-none">
                <MobileFooter />
            </div>
            <div className="d-none d-lg-block">
                <DesktopFooter />
            </div>
        </div>
    );
};

export default Footer;
