import React from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";

const PageMode = () => {
    const history = useHistory();
    return (
        <div className="container">
            <h2 className="mt-3">Website currently under maintenance</h2>
            <p>
                Please wait a few minutes and{" "}
                <Link
                    to={history.location.pathname}
                    onClick={() => history.go(0)}
                >
                    click here to try again
                </Link>{" "}
            </p>
        </div>
    );
};

export default PageMode;
