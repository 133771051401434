import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col } from "reactstrap";
import { Form, FormGroup } from "reactstrap";
import { useForm } from "react-hook-form";
import ValidatingField from "../../ValidatingField";
import { useUser } from "../../../hooks/user";
import { useNewSession } from "../../../hooks/useNewSession";
import { useGetStyling } from "../../../hooks/useStyling";
import IndeterminateButton from "../../IndeterminateButton";
import { encode } from "base-64";
import AssemblyProvider from "../../AssemblyProvider";
import { setStyling } from "../../../reducers/rootReducer";

const Verify = ({ username, toggleIsResettingPassword }) => {
    const dispatch = useDispatch();
    const sessionID = useSelector((state) => state.root.userSession.sessionID);
    const { execute, isLoading: userIsLoading } = useUser();
    const { create: createNewSession } = useNewSession();
    const { execute: getStyling, isLoading: isStylingLoading } =
        useGetStyling();
    const registerForm = useForm();
    const { handleSubmit, setError } = registerForm;
    const isLoading = userIsLoading || isStylingLoading;

    const resendCode = async () => {
        await execute(`forgot-password`, {
            method: "POST",
            body: JSON.stringify({ username }),
        });
    };

    const onSubmit = async (data) => {
        const { password, password_verify, reset_code } = data;
        const verificationResponse = await execute("forgot-password/confirm", {
            method: "POST",
            headers: {
                authorization: `Basic ${encode(`${username}:${password}`)}`,
            },
            body: JSON.stringify({
                reset_code,
                password_verify: encode(password_verify),
            }),
        });
        if (verificationResponse?.entryDefects) {
            verificationResponse.entryDefects.entryDefects.forEach((defect) => {
                const name = Object.keys(defect)[0];
                setError(name, { message: defect[name] });
            });
        } else {
            toggleIsResettingPassword();
            if (verificationResponse?.loginRequired) {
                createNewSession();
            } else {
                // At some point we should be getting styling data in the response and should not have to go get it here
                dispatch(setStyling(await getStyling(sessionID)));
            }
        }
    };

    return (
        <AssemblyProvider
            group="SIGNUP-PW_ESTABLISH_REQUEST"
            name="SIGNUP-PW_ESTABLISH_REQUEST"
        >
            {(assembly, assemblyIsLoading) => (
                <>
                    {assemblyIsLoading ? (
                        <div>Loading</div>
                    ) : (
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <Row>
                                <Col>
                                    <ValidatingField
                                        name="reset_code"
                                        assembly={assembly}
                                        {...registerForm}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <ValidatingField
                                        name="password"
                                        type="password"
                                        assembly={assembly}
                                        {...registerForm}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <ValidatingField
                                        name="password_verify"
                                        type="password"
                                        assembly={assembly}
                                        {...registerForm}
                                    />
                                </Col>
                            </Row>
                            <FormGroup className="d-flex">
                                <IndeterminateButton
                                    color="link"
                                    className="ms-auto"
                                    value="Resend Code"
                                    isLoading={isLoading}
                                    onClick={() => resendCode()}
                                >
                                    Resend Code
                                </IndeterminateButton>
                                <IndeterminateButton
                                    type="submit"
                                    className="btn btn-primary "
                                    value="Verify"
                                    isLoading={isLoading}
                                >
                                    Update Password
                                </IndeterminateButton>
                            </FormGroup>
                        </Form>
                    )}
                </>
            )}
        </AssemblyProvider>
    );
};

export default Verify;
