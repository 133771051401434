import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Button,
    Navbar,
    Nav,
    NavItem,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
} from "reactstrap";
import PubSub from "pubsub-js";
import styled from "styled-components";
import useToggle from "../../hooks/useToggle";
import Account from "../Account/Account";
import HeaderSearch from "./HeaderSearch";
import { setMode, setPageError } from "../../reducers/rootReducer";
import NavLink from "./NavLink";
import useTrackEvent from "../../hooks/useTrackEvent";
import CdnImage from "../CdnImage";

const Desktop = ({
    styling: {
        nicheName,
        nichePhoneNumber,
        nichetypePlural,
        nicheSearchBoxText,
    },
    navItems,
    subNavLength = 7,
}) => {
    const dispatch = useDispatch();
    const mode = useSelector((state) => state.root.mode);
    const { trackEvent, trackGTM } = useTrackEvent();

    const handleCLick = () => {
        // We want to clear the search when the user visits the state page.
        PubSub.publish("RESET_SEARCH");
        if (mode === "maintenance") {
            dispatch(setPageError());
            dispatch(setMode("normal"));
        }
    };

    return (
        <div className="container-xl position-relative">
            <div className="d-flex">
                <div className="d-flex align-items-center">
                    <NavLink to="/" onClick={handleCLick} className="me-2">
                        <CdnImage
                            style={{ height: "60px" }}
                            src="/header-logo.png"
                            alt={nicheName}
                        />
                    </NavLink>
                    <Navbar className="p-0 m-0" expand={true}>
                        <Nav className="m-0" navbar>
                            {navItems.map((item, i) => {
                                if (item.children) {
                                    return (
                                        <NavDropDown
                                            key={`dropdown-${i}`}
                                            label={item.label}
                                            data-test={`nav-item-${item.label
                                                .replace(/ /g, "-")
                                                .toLowerCase()}`}
                                        >
                                            <Ul
                                                className="list-inline"
                                                columns={
                                                    Array.isArray(
                                                        item.children
                                                    ) &&
                                                    Math.ceil(
                                                        item.children.length /
                                                            subNavLength
                                                    )
                                                }
                                            >
                                                {Array.isArray(item.children) &&
                                                    item.children.map(
                                                        (childItem, ci) => (
                                                            <li
                                                                key={`drop-down-child-${ci}`}
                                                                className="px-1 px-xl-3"
                                                            >
                                                                <NavLink
                                                                    className="nav-link text-primary-dark-alt p-0 text-nowrap"
                                                                    to={{
                                                                        pathname:
                                                                            childItem.link,
                                                                        state: {
                                                                            stateId:
                                                                                childItem?.stateCode,
                                                                        },
                                                                    }}
                                                                    onClick={
                                                                        handleCLick
                                                                    }
                                                                >
                                                                    {
                                                                        childItem.label
                                                                    }
                                                                </NavLink>
                                                            </li>
                                                        )
                                                    )}
                                            </Ul>
                                        </NavDropDown>
                                    );
                                } else {
                                    return (
                                        <NavItem
                                            key={`navItem-${i}`}
                                            data-test={`nav-item-${item?.label
                                                .replace(/ /g, "-")
                                                .toLowerCase()}`}
                                        >
                                            <NavLink
                                                className="nav-link text-primary-dark-alt text-nowrap"
                                                to={item.link}
                                                onClick={handleCLick}
                                            >
                                                {item.label}
                                            </NavLink>
                                        </NavItem>
                                    );
                                }
                            })}
                        </Nav>
                    </Navbar>
                </div>
                <div className="ms-auto pl-1 d-flex align-items-center justify-content-end">
                    <Button
                        color="link"
                        className="me-4 px-0 text-primary-dark-alt text-nowrap"
                        href={`tel:${nichePhoneNumber}`}
                        onClick={() => {
                            trackGTM({
                                event: `phoneClick`,
                                action: "click",
                                type: "Header Phone",
                                category: "contact",
                                button_label: nichePhoneNumber,
                                value: nichePhoneNumber,
                            });
                            trackEvent("ACTIONS", {
                                CATEGORY: "event",
                                ASSOCIATE: "Header Phone",
                                ACTION: "click",
                                SOURCE_INFO: {
                                    eventLabel: nichePhoneNumber,
                                    eventValue: nichePhoneNumber,
                                },
                            });
                        }}
                    >
                        {nichePhoneNumber}
                    </Button>
                    {mode !== "maintenance" && (
                        <>
                            <Account className="me-4 px-0 text-primary-dark-alt text-nowrap" />
                            <HeaderSearch
                                className=""
                                iconClassName="px-0 text-primary-dark-alt"
                                placeholder={nicheSearchBoxText}
                                nichetypePlural={nichetypePlural}
                            />
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

const NavDropDown = ({ children, label, ...props }) => {
    const [isOpen, toggle] = useToggle(false);
    return (
        <UncontrolledDropdown
            nav
            inNavbar
            onMouseOver={() => toggle(true)}
            onMouseLeave={() => toggle(false)}
            isOpen={isOpen}
            toggle={() => toggle()}
            {...props}
        >
            <DropdownToggle nav className="text-primary-dark-alt text-nowrap">
                {label}
            </DropdownToggle>
            <DropdownMenu className="p-2 mt-0">{children}</DropdownMenu>
        </UncontrolledDropdown>
    );
};

const Ul = styled.ul`
    columns: ${(props) => props.columns};
    -webkit-columns: ${(props) => props.columns};
    -moz-columns: ${(props) => props.columns};
`;

export default Desktop;
