import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import UsaMap from "../UsaMap";
import styled from "styled-components";

/**
 * MapOfUS Component.
 *
 * This component renders an interactive map of the United States using the UsaMap component. It allows users to click
 * on different states to navigate to specific links.
 *
 * @param {string} [className] - Optional CSS class to add to the component wrapper.
 * @param {Object} [style] - Optional inline styles to apply to the component wrapper.
 * @param {boolean} [showToolTip=true] - Determines if tooltips should be shown on state hover.
 * @param {boolean} [showPopOver=false] - Determines if popovers should be shown on state hover.
 * @param {Object} [mapData] - Optional custom data to use for rendering the map states.
 */

const MapOfUS = ({
    className,
    style,
    showToolTip = true,
    showPopOver = false,
    mapData,
}) => {
    const states = useSelector((state) => state.root.styling.states);
    const history = useHistory();
    const [mapWidth, setmapWidth] = useState("100%");
    const setMapHeight = () => {
        setmapWidth(
            document.getElementsByClassName("map-wraper")[0].offsetWidth
        );
    };

    useEffect(() => {
        window.addEventListener("resize", setMapHeight);
        return (_) => {
            window.removeEventListener("resize", setMapHeight);
        };
    });
    useEffect(() => {
        if (states) {
            setMapHeight();
        }
    }, [states]);

    const buildMapData = (statesList) => {
        const data = {};
        statesList.forEach((usaState) => {
            if (!usaState?.stateStatus) {
                data[usaState.stateCode] = {
                    styles: { fill: "var(--bs-primary)" },
                    hoverStyles: { fill: "var(--bs-primary-dark)" },
                    href: usaState.valueLink,
                    clickHandler: () => {
                        history.push(usaState.valueLink, {
                            stateId: usaState.stateCode,
                        });
                    },
                    isExcluded: false,
                };
            } else if (usaState?.stateStatus === "Coming Soon") {
                data[usaState.stateCode] = {
                    styles: { fill: "var(--bs-gray-500)" },
                    hoverStyles: { fill: "var(--bs-gray-600)" },
                    isExcluded: false,
                };
            } else {
                data[usaState.stateCode] = {
                    styles: { fill: "var(--bs-gray-400)" },
                    isExcluded: true,
                };
            }
        });
        return data;
    };

    return (
        <>
            {states && (
                <MapWrapper className={`${className} map-wraper`} style={style}>
                    <UsaMap
                        width={mapWidth}
                        customize={
                            mapData || buildMapData(Object.values(states))
                        }
                        defaultStyles={{ fill: "var(--bs-primary)" }}
                        defaultHoverStyles={{ fill: "var(--bs-primary-dark)" }}
                        showPopOver={showPopOver}
                        showToolTip={showToolTip}
                    />
                </MapWrapper>
            )}
        </>
    );
};

const MapWrapper = styled.div`
    & .us-state-map {
        transition: width 0.5s;
    }
`;

export default MapOfUS;
