import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    featured: undefined,
    byId: {},
};

const featuredAgentSlice = createSlice({
    name: "featuredAgents",
    initialState,
    reducers: {
        setFeaturedAgent: (state, action) => {
            state.featured = action.payload;
        },
        addAgent: (state, action) => {
            const id = action.payload?.agentName?.more?.id;
            state.byId[id] = [...(state.byId[id] || []), action.payload];
        },
        removeAgent: (state, action) => {
            if (action.payload) {
                delete state.byId[action.payload];
            }
        },
    },
});

// Extract the action creators object and the reducer
const { actions, reducer } = featuredAgentSlice;
// Extract and export each action creator by name
export const { setFeaturedAgent, addAgent, removeAgent } = actions;
// Export the reducer, either as a default or named export
export default reducer;
