import React from "react";
import { Link } from "react-router-dom";
import { FaSearch, FaHome } from "react-icons/fa";
import { BsGearFill } from "react-icons/bs";

const Summary = ({ closeModal }) => (
    <>
        <Link
            className="btn btn-link d-block px-0 text-start"
            color="link"
            to="/account/searches"
            onClick={() => {
                if (closeModal) closeModal();
            }}
        >
            <FaSearch className="me-2" /> Saved Searches
        </Link>
        <Link
            className="btn btn-link d-block px-0 text-start"
            color="link"
            to="/account/properties"
            onClick={() => {
                if (closeModal) closeModal();
            }}
        >
            <FaHome className="me-2" /> Saved Properties
        </Link>
        <Link
            className="btn btn-link d-block px-0 text-start"
            color="link"
            to="/account"
            onClick={() => {
                if (closeModal) closeModal();
            }}
        >
            <BsGearFill className="me-2" />
            Account Settings
        </Link>
    </>
);

export default Summary;
