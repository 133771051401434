import React from "react";
import { useDispatch } from "react-redux";
import { Button } from "reactstrap";
import { useHistory, Link } from "react-router-dom";
import { setPageError } from "../../reducers/rootReducer";
import HeroErrorWrapper from "../HeroErrorWrapper";
import { useNacLocation } from "../../hooks/useNacLocation";

const PageError = () => {
    const dispatch = useDispatch();
    const { pathname, state } = useNacLocation();
    const history = useHistory();

    const handleTryAgain = () => {
        dispatch(setPageError());
        history.push({ pathname, state });
    };

    return (
        <HeroErrorWrapper className="w-100">
            <h2 className="py-3">Oops! We hit a snag.</h2>
            <p>
                We're really sorry, but we're having a bit of trouble loading
                the page you're looking for. Don't worry though, we're on it!
            </p>
            <p>
                Why not give it another shot?{" "}
                <Button
                    color="link"
                    className="p-0"
                    onClick={() => handleTryAgain()}
                >
                    Click here to refresh the page.
                </Button>
            </p>
            <p>
                If you prefer, you can also{" "}
                <Link to="/" onClick={() => dispatch(setPageError())}>
                    return to the home page.
                </Link>{" "}
                We appreciate your patience!
            </p>
        </HeroErrorWrapper>
    );
};

export default PageError;
