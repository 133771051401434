import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { matchPath } from "react-router-dom";
import AdminToggle from "../Account/AdminToggle";
import { setMode } from "../../reducers/rootReducer";
import { getPageType } from "../../support/helpers";
import Banner from "../Banner";
import { useNacLocation } from "../../hooks/useNacLocation";

const AdminBanner = () => {
    const dispatch = useDispatch();
    const { pathname, state: { agentId: initialAgentId } = {} } =
        useNacLocation();
    const { params } = matchPath(pathname, {
        path: "/:state?/:niche?/:three?",
    });

    // Preventing ssr conditional rendering issue --
    // agentId is found on the server but not on the client
    // for the initial render.
    let agentId = initialAgentId;
    if (!agentId && typeof window !== "undefined" && window !== null) {
        const stateAgentId = Object.keys(
            window?.initialState?.pages?.agents || {}
        )[0];
        if (stateAgentId) {
            agentId = stateAgentId;
        }
    }
    const page = getPageType(params, null, agentId);
    const mode = useSelector((state) => state.root.mode);
    const isMobile = useSelector((state) => state.root.isMobile);
    const permissions = useSelector(
        (state) => state.root.userSession.webAccess4
    );
    const isOffMarket = page === "offmarket";
    const isActive = mode === "edit" ? true : false;
    let hasPagePermission = false;

    useEffect(() => {
        if (mode === "edit") dispatch(setMode("normal"));
    }, [pathname]);

    if (permissions) {
        let perms = permissions?.["MICRO-CONTENT"];
        if (
            ((page === "niche" || page === "development") &&
                perms?.["NICHE-ITEMS"]) ||
            (page === "state" && perms?.["STATES"]) ||
            (page === "agent" && perms?.["AGENTS"])
        ) {
            hasPagePermission = true;
        }
    }

    if (!hasPagePermission || isMobile || isOffMarket) return null;
    return (
        <Banner bannerColor={isActive ? "warning" : "primary"}>
            <AdminToggle text="Edit Mode:" />
        </Banner>
    );
};

export default AdminBanner;
