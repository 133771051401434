import React from "react";
import { Button } from "reactstrap";
import styled from "styled-components";
import { Hero } from "..";
import Loading from "../../../Loading";
import { useWordpress } from "../../../../hooks/useWordpress";
import useTrackEvent from "../../../../hooks/useTrackEvent";

const Post = ({ post }) => {
    const { execute, isLoading, data: media } = useWordpress();
    const { trackGTM } = useTrackEvent();

    if (!media && !isLoading) {
        execute(`media?parent=${post.id}`);
    }

    // This replaces the rendered elipses from a string
    const parseExcript = (text) => {
        return text.replace(/<[^>]*>/g, "").replace("[&hellip;]", "");
    };

    const handleClick = () => {
        trackGTM({
            event: `viewLifestylesPost`,
            action: "click",
            category: "lifestyles",
            type: post.title.rendered,
        });
    };

    return (
        <Hero path={media ? media[0]?.source_url : null}>
            <div className="d-flex flex-column" style={{ height: "400px" }}>
                {isLoading && <Loading loadingLabel="" />}
                <div
                    className="m-3 mt-auto p-3"
                    style={{
                        zIndex: 1,
                        backgroundColor: "rgba(100%, 100%, 100%, .7)",
                    }}
                >
                    <h5
                        dangerouslySetInnerHTML={{
                            __html: post.title.rendered,
                        }}
                    ></h5>
                    <Truncated>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: parseExcript(post.excerpt.rendered),
                            }}
                        />
                    </Truncated>
                    <Button
                        className="p-0"
                        color="link"
                        href={post.link}
                        onClick={handleClick}
                    >
                        Read More
                    </Button>
                </div>
            </div>
        </Hero>
    );
};

const Truncated = styled.div`
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: ${(props) => props.lines || 2};
    -webkit-box-orient: vertical;
`;

export default Post;
