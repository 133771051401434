import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { Button, Form, FormGroup, Row, Col, Alert } from "reactstrap";
import ValidatingField from "../../ValidatingField";
import ReCaptchaFormItem from "../../ReCaptchaFormItem";
import IndeterminateButton from "../../IndeterminateButton";
import { useUser } from "../../../hooks/user";
import { setAssemblies } from "../../../reducers/rootReducer";
import AssemblyProvider from "../../AssemblyProvider";

function Request({
    setUsername,
    username,
    toggleIsVerifying,
    toggleIsResettingPassword,
}) {
    const dispatch = useDispatch();
    const { execute, isLoading: isUserLoading } = useUser();
    const [accountNotFound, setAccountNotFound] = useState(false);

    const registerForm = useForm();
    const { handleSubmit, setError } = registerForm;

    const onSubmit = async (data) => {
        setUsername(data.username);
        setAccountNotFound(false);
        const response = await execute("forgot-password", {
            method: "POST",
            body: JSON.stringify(data),
        });
        if (response?.entryDefects) {
            response.entryDefects.forEach((defect) => {
                const name = Object.keys(defect)[0];
                setError(name, { message: defect[name] });
            });
        } else if (response) {
            dispatch(
                setAssemblies({ FORGOTPW_REQUEST_CONFIRM: response.assembly })
            );
            toggleIsVerifying();
        } else {
            setAccountNotFound(true);
        }
    };

    return (
        <AssemblyProvider group="USER_ACCOUNT" name="FORGOTPW">
            {(assembly) => (
                <Form onSubmit={handleSubmit(onSubmit)}>
                    {accountNotFound && (
                        <Alert color="warning">
                            We seem to be having some trouble finding your
                            account <strong>{username || ""}</strong>. Please
                            try again.
                        </Alert>
                    )}
                    <Row>
                        <Col>
                            <ValidatingField
                                name="username"
                                assembly={assembly}
                                {...registerForm}
                            />
                        </Col>
                    </Row>
                    <Row className="align-items-center">
                        <Col>
                            <FormGroup>
                                <ReCaptchaFormItem {...registerForm} />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup className="d-flex">
                                <Button
                                    className="ms-auto"
                                    color="link"
                                    onClick={() => toggleIsResettingPassword()}
                                >
                                    Cancel
                                </Button>
                                <IndeterminateButton
                                    type="submit"
                                    className="btn btn-primary"
                                    value="Sign In"
                                    isLoading={isUserLoading}
                                >
                                    Reset
                                </IndeterminateButton>
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
            )}
        </AssemblyProvider>
    );
}

export default Request;
