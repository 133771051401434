import React from "react";
import styled from "styled-components";

const Divider = ({ children, content = "|", ...props }) => (
    <StyledDivider content={content} {...props}>
        {children}
    </StyledDivider>
);

const StyledDivider = styled.span`
    &:after {
        content: " ${(props) => props.content} ";
        color: var(--bs-gray-500);
        vertical-align: middle;
    }
    &:last-child:after {
        content: "";
    }
`;

export default Divider;
