import React from "react";
import styled from "styled-components";
import { FaChevronRight, FaChevronLeft } from "react-icons/fa";

const ButtonGroup = ({ next, previous }) => {
    return (
        <ButtonGroupWrapper className="button-group position-absolute">
            <div
                className="d-inline-block px-3 py-2"
                onClick={() => previous()}
            >
                <FaChevronLeft size="10" style={{ marginLeft: "-2px" }} />
            </div>
            <div
                className="d-inline-block px-3 py-2 ms-1"
                onClick={() => next()}
            >
                <FaChevronRight size="10" style={{ marginRight: "-2px" }} />
            </div>
        </ButtonGroupWrapper>
    );
};

const ButtonGroupWrapper = styled.div`
    top: -46px;
    right: 5px;
    > div {
        cursor: pointer;
    }
    @media (max-width: 400px) {
        display: none;
    }
`;

export default ButtonGroup;
