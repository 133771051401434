import { useState } from "react";

const useToggle = (initialState) => {
    const [value, setValue] = useState(initialState);
    const toggle = (v) => {
        setValue(v !== undefined && typeof v === "boolean" ? v : !value);
    };

    return [value, toggle];
};

export default useToggle;
