import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";

class AccountErrorBoundry extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        // TODO: add API endpoint that will log FE errors to the DB
        // We can dispatch/follow this pattern.
        // logErrorToMyService(error, errorInfo);
        console.log(error, errorInfo);
    }

    componentDidUpdate(prevProps) {
        const { location } = this.props;
        if (location.pathname !== prevProps.location.pathname) {
            this.setState(() => ({
                hasError: false,
            }));
            // Trigger any necessary state updates or other actions here
        }
    }

    render() {
        const { hasError } = this.state;
        const { children, pathname = "/" } = this.props;

        return (
            <>
                {hasError ? (
                    <div className="container">
                        <h3>Something Went Wrong</h3>
                        <p>
                            <Link to={pathname}>Click here to try again</Link>{" "}
                        </p>
                    </div>
                ) : (
                    children
                )}
            </>
        );
    }
}

export default withRouter(AccountErrorBoundry);
