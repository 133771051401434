import React from "react";
import styled from "styled-components";
import { ToastContainer, Slide } from "react-toastify";

function ToastProvider({ children }) {
    return (
        <>
            {children}
            <StyledToastContainer
                position="bottom-center"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                closeButton={false}
                transition={Slide}
                limit={5}
            />
        </>
    );
}

const StyledToastContainer = styled(ToastContainer)`
    &&&.Toastify__toast-container {
        bottom: 0;
        min-height: 50px;
    }
    .Toastify__toast {
        border-radius: 6px;
        padding-y: 0;
        min-height: 50px;
        width: 380px;
    }
    .Toastify__toast-body {
        color: black;
        padding-y: 0;
        margin: 0;
    }
    .Toastify__toast--success {
        border-left: var(--bs-secondary) 7px solid;
    }
    .Toastify__toast--error {
        border-left: var(--bs-danger) 7px solid;
    }

    @media only screen and (max-width: 480px) {
        .Toastify__toast {
            margin: 10px;
            width: auto;
        }
    }
`;

export default ToastProvider;
