import { useState, useCallback } from "react";
import { getDomain } from "../../src/support/helpers";

export const getWordpressResource = async (recource) => {
    // This will return the domain w/o the www.
    const baseDomain = getDomain(location.hostname);
    const splitDomain = baseDomain.split(".");
    // if we have 3 parts, its a subdomain (ex dev.lakehomes.com) resolve it. Otherwise add the www
    const domain = splitDomain.length === 2 ? `www.${baseDomain}` : baseDomain;
    const response = await fetch(
        `https://${domain}/info/wp-json/wp/v2/${recource}`
    );
    return await response.json();
};

export const useWordpress = (initialResults = null) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState(initialResults);

    const execute = async (recource) => {
        try {
            setError(null);
            setIsLoading(true);
            const results = await getWordpressResource(recource);
            setData(results);
            setIsLoading(false);
            return results;
        } catch (e) {
            setError(e);
            setIsLoading(false);
        }
    };

    return {
        isLoading,
        error,
        data,
        execute: useCallback(execute, []), // to avoid infinite calls when inside a `useEffect`
        clear: () => setData(null),
    };
};
