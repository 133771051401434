import { useState, useCallback } from "react";
import { useSelector } from "react-redux";
import {
    getBaseUrl,
    buildQueryParams,
    fetchOverlord,
} from "../support/helpers";

export const getPage = async (
    sessionId,
    url,
    queryObj = {},
    headers = {},
    baseUrl
) => {
    try {
        if (!url.includes("offmarket")) queryObj.gispreview = true;
        const query = buildQueryParams(queryObj);
        const response = await fetchOverlord(`${baseUrl}/page${url}${query}`, {
            headers: {
                "Content-Type": "application/json",
                sessionId,
                ...headers,
            },
        });
        if (queryObj?._mode) response.mode = queryObj._mode;
        return response;
    } catch (e) {
        console.log("failed to get page", e);
        throw e;
    }
};

export const useGetPage = (initialResults = null) => {
    const sessionID = useSelector((state) => state.root.userSession.sessionID);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState(initialResults);

    const execute = async (url, query, sessionId = sessionID) => {
        try {
            setIsLoading(true);
            setError(null); // resetting error
            const baseUrl = getBaseUrl();
            const results = await getPage(sessionId, url, query, {}, baseUrl);
            setData(results);
            setIsLoading(false);
            return results;
        } catch (e) {
            setError(e);
            setIsLoading(false);
        }
    };

    return {
        isLoading,
        error,
        data,
        execute: useCallback(execute, [sessionID]), // to avoid infinite calls when inside a `useEffect`
        clear: () => setData(null),
    };
};
