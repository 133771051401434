import React, { useState } from "react";
import { Alert } from "reactstrap";

const Banner = ({
    bannerText,
    bannerColor,
    children,
    dismissable = false,
    ...props
}) => {
    const [isOpen, setIsOpen] = useState(true);

    const toggle = () => setIsOpen(!isOpen);

    return (
        <div className="w-100">
            <Alert
                color={bannerColor}
                className="pt-2"
                isOpen={isOpen}
                toggle={dismissable ? toggle : undefined}
                {...props}
            >
                {bannerText} {children}
            </Alert>
        </div>
    );
};

export default Banner;
