import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Row, Col } from "reactstrap";
import { Form, FormGroup } from "reactstrap";
import { useForm } from "react-hook-form";
import ValidatingField from "../../ValidatingField";
import { useUser } from "../../../hooks/user";
import IndeterminateButton from "../../IndeterminateButton";
import { useGetStyling } from "../../../hooks/useStyling";
import AssemblyProvider from "../../AssemblyProvider";
import Curtain from "../../Curtain";
import { setStyling } from "../../../reducers/rootReducer";

const Verify = ({ cancel, complete, setAlertData }) => {
    const dispatch = useDispatch();
    const sessionID = useSelector((state) => state.root.userSession.sessionID);
    const { execute: getStyling, isLoading: isStylingLoading } =
        useGetStyling();
    const {
        execute,
        isLoading: isUserLoading,
        data: userData,
        error: userError,
    } = useUser();
    const { execute: executeSendCode, isLoading: sendCodeLoading } = useUser();
    const isLoading = isUserLoading || isStylingLoading;
    const registerForm = useForm();
    const { handleSubmit, setError } = registerForm;

    const onSubmit = (data) => {
        const { reset_code, email } = data;
        execute("userID/email/verify/confirm", {
            method: "POST",
            body: JSON.stringify({ reset_code, email }),
        });
    };

    const sendCode = async () =>
        await executeSendCode(`userID/email/verify`, {
            method: "POST",
            body: JSON.stringify({}),
        });

    useEffect(() => {
        if (userData !== null && !userError) {
            // User data is returned as a 204 and will change from null to undefined
            let fetchData;
            if (userData?.entryDefects) {
                userData.entryDefects.entryDefects.forEach((defect) => {
                    const name = Object.keys(defect)[0];
                    setError(name, { message: defect[name] });
                });
            } else {
                fetchData = async () => {
                    const stylingResponse = await getStyling(sessionID);
                    dispatch(setStyling(stylingResponse));
                    await complete();
                };
            }
            if (fetchData) fetchData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userData]);

    useEffect(() => {
        if (userError && setAlertData) {
            setAlertData({
                color: "danger",
                alertText: (
                    <>
                        Sorry, we had an issue updating your information. Please{" "}
                        <Button
                            className="p-0"
                            color="link"
                            onClick={handleSubmit(onSubmit)}
                        >
                            click here to try again.
                        </Button>
                    </>
                ),
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userError]);

    return (
        <AssemblyProvider
            group="VERIFY-EMAIL_REQUEST"
            name="VERIFY-EMAIL_REQUEST"
        >
            {(assembly, isAssemblyLoading) => (
                <Curtain
                    show={isLoading && isAssemblyLoading && !assembly}
                    withLoading
                >
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col>
                                <ValidatingField
                                    name="email"
                                    assembly={assembly}
                                    {...registerForm}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <ValidatingField
                                    name="reset_code"
                                    assembly={assembly}
                                    {...registerForm}
                                />
                            </Col>
                        </Row>
                        <FormGroup className="d-flex">
                            <IndeterminateButton
                                color="link"
                                className="ms-auto"
                                isLoading={false}
                                onClick={() => cancel()}
                            >
                                Cancel
                            </IndeterminateButton>
                            <IndeterminateButton
                                color="link"
                                isLoading={sendCodeLoading}
                                onClick={() => sendCode()}
                            >
                                Resend Code
                            </IndeterminateButton>
                            <IndeterminateButton
                                type="submit"
                                className="btn btn-primary "
                                value="Sign In"
                                isLoading={isUserLoading}
                            >
                                Verify
                            </IndeterminateButton>
                        </FormGroup>
                    </Form>
                </Curtain>
            )}
        </AssemblyProvider>
    );
};

export default Verify;
