import { useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { getBaseUrl, fetchOverlord } from "../support/helpers";

export const getUser = async (pathPart, sessionId, options, retries = 3) => {
    const idPathPart = pathPart ? `/${pathPart}` : "";
    const response = await fetchOverlord(
        `${getBaseUrl()}/users${idPathPart}`,
        {
            ...options,
            headers: {
                "Content-Type": "application/json",
                sessionId,
                ...options?.headers,
            },
        },
        retries
    );
    return response;
};

export const useUser = (initialResults = null, retries = 3) => {
    const sessionID = useSelector((state) => state.root.userSession.sessionID);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState(initialResults);

    const execute = async (pathPart, options) => {
        try {
            setIsLoading(true);
            setError(null);
            const results = await getUser(
                pathPart,
                sessionID,
                options,
                retries
            );
            setData(results);
            setIsLoading(false);
            return results;
        } catch (e) {
            setData(null);
            setError(e);
            setIsLoading(false);
        }
    };

    return {
        isLoading,
        error,
        data,
        execute: useCallback(execute, [sessionID]), // to avoid infinite calls when inside a `useEffect`
        clear: () => setData(null),
    };
};
