/*
 * ------------------------------------------------------------------------------------
 * DESCRIPTION:
 * ------------------------------------------------------------------------------------
 *      SocialBar component
 *
 * ------------------------------------------------------------------------------------
 * VERSION HISTORY: (NOTE: The version variables must be updated manually.)
 * ------------------------------------------------------------------------------------
 *                   Changed By
 *      Date         Initials    Version #  Sub-Version  Notes
 *      -----------  ----------  ---------  -----------  ------------------------------
 *      2022-11-15   BL          22319      1            Re-structured files and renamed to index.js
 *
 */
import React from "react";
import { useSelector } from "react-redux";
import { Button } from "reactstrap";
import {
    FaFacebookF,
    FaXTwitter,
    FaInstagram,
    FaPinterest,
    FaYoutube,
    FaLinkedinIn,
} from "react-icons/fa6";

// TODO: Add Facebook share ID
// TODO: add email settings

const SocialBar = () => {
    const social = useSelector((state) => state.root.styling.microSocial);
    return (
        <div className="d-flex align-items-center my-3 my-md-0">
            <div className="d-inline-block">Follow Us:</div>
            <div>
                {social?.facebook && (
                    <Button
                        color="link"
                        className="p-0 ms-2 text-white"
                        href={social.facebook}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <FaFacebookF />
                    </Button>
                )}
                {social?.twitter && (
                    <Button
                        color="link"
                        className="p-0 ms-2 text-white"
                        href={social.twitter}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <FaXTwitter />
                    </Button>
                )}
                {social?.instagram && (
                    <Button
                        color="link"
                        className="p-0 ms-2 text-white"
                        href={social.instagram}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <FaInstagram />
                    </Button>
                )}
                {social?.pinterest && (
                    <Button
                        color="link"
                        className="p-0 ms-2 text-white"
                        href={social.pinterest}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <FaPinterest />
                    </Button>
                )}
                {social?.linkedIn && (
                    <Button
                        color="link"
                        className="p-0 ms-2 text-white"
                        href={social.linkedIn}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <FaLinkedinIn />
                    </Button>
                )}
                {social?.youTube && (
                    <Button
                        color="link"
                        className="p-0 ms-2 text-white"
                        href={social.youTube}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <FaYoutube />
                    </Button>
                )}
            </div>
        </div>
    );
};

export default SocialBar;
