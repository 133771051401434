/*
 * ------------------------------------------------------------------------------------
 * DESCRIPTION:
 * ------------------------------------------------------------------------------------
 *       ListingItem component
 *
 * ------------------------------------------------------------------------------------
 * VERSION HISTORY: (NOTE: The version variables must be updated manually.)
 * ------------------------------------------------------------------------------------
 *                   Changed By
 *      Date         Initials    Version #  Sub-Version  Notes
 *      -----------  ----------  ---------  -----------  ------------------------------
 *      2022-11-17   BL          22321      1            Re-structured files and renamed to index.js
 *
 */
import React from "react";
import { useDispatch } from "react-redux";
import { Card, CardBody, CardImg, CardTitle } from "reactstrap";
import { Link, useParams } from "react-router-dom";
import { formatCurrency, getPageType } from "../../support/helpers";
import Badges from "../Badges";
import SaveProperty from "../SaveProperty";
import { setActiveMarker } from "../../reducers/mapReducer";
import Divider from "../Divider";
import { makePlural } from "../../support/helpers";
import { useNacLocation } from "../../hooks/useNacLocation";

const ListingItem = ({
    listing,
    className = "",
    showNicheItem = true,
    isOffmarket = false,
}) => {
    const dispatch = useDispatch();
    const { state: locationState } = useNacLocation();
    const routeParams = useParams();
    const page = getPageType(routeParams);
    const listingId = listing.listingId || listing.id;

    return (
        <div className={`${className}`}>
            <Card
                className="h-100 border-0 mx-1 shadow-sm rounded-0 position-relative"
                onMouseEnter={() => dispatch(setActiveMarker(listingId))}
                onMouseLeave={() => dispatch(setActiveMarker())}
            >
                <Badges
                    className="position-absolute"
                    style={{ top: "8px", left: "2px" }}
                    showLimit={2}
                    isFeatured={listing?.featured}
                    isPriceChanged={listing?.priceChanged}
                    isNew={listing?.new}
                    isWaterfront={listing?.isWaterfront}
                    isOffLake={listing?.nicheItem === "Off Lake"}
                    isOffMarket={isOffmarket}
                />
                {!isOffmarket && (
                    <SaveProperty
                        listingId={listingId}
                        color="link"
                        className={`position-absolute p-0 m-0 no-focus-shadow text-white`}
                        heartClassName="text-white"
                        style={{ top: "0", right: "5px", fontSize: "1.5rem" }}
                        location={`${page} preview`}
                    />
                )}
                <Link
                    className="text-dark h-100 nounderline"
                    to={{
                        pathname: listing.urlPath,
                        state: {
                            ...locationState,
                            listingId: listingId,
                            isOffmarket,
                        },
                    }}
                >
                    <CardImg
                        top
                        width="100%"
                        loading="lazy"
                        height="150px"
                        alt={listing?.addressLine1 || listingId}
                        src={
                            listing?.image ||
                            "/assets/no-image-found-360x260.png"
                        }
                        style={{ objectFit: "cover" }}
                        onError={(e) =>
                            (e.target.src = "/no-image-found-360x260.png")
                        }
                    />
                    <CardBody className="p-2">
                        {listing?.price && (
                            <CardTitle className="mb-0">
                                <strong>{formatCurrency(listing.price)}</strong>
                            </CardTitle>
                        )}
                        <div className="d-flex flex-wrap">
                            <div className="small">
                                {showNicheItem && (
                                    <div>{listing?.nicheItem}</div>
                                )}
                                <div className="text-muted address small">
                                    {`${listing?.addressLine1}, `}
                                    {listing?.addressLine2}
                                </div>
                                <div>
                                    {listing?.bedrooms && (
                                        <Divider>
                                            {listing.bedrooms}{" "}
                                            {makePlural(
                                                "Bed",
                                                "Beds",
                                                listing.bedrooms
                                            )}
                                        </Divider>
                                    )}
                                    {listing?.bathrooms && (
                                        <Divider>
                                            {listing.bathrooms}{" "}
                                            {makePlural(
                                                "Bath",
                                                "Baths",
                                                listing.bathrooms
                                            )}
                                        </Divider>
                                    )}
                                    {listing?.acreage?.readable && (
                                        <Divider>
                                            {listing.acreage.readable}
                                        </Divider>
                                    )}
                                </div>
                                {listing?.status && (
                                    <div className="text-muted small status">
                                        Status: {listing.status}
                                    </div>
                                )}
                                {listing?.mlsNum && (
                                    <div className="text-muted mls-number">
                                        {listing.mlsNum}
                                    </div>
                                )}
                                {listing?.mlsBrokerAgent && (
                                    <div className="text-muted mls-broker-agent">
                                        {listing.mlsBrokerAgent}
                                    </div>
                                )}
                            </div>
                            {listing?.dISearchCreditLogo?.imageUrl && (
                                <div className="credit-logo ms-auto d-flex align-items-end">
                                    <img
                                        src={
                                            listing?.dISearchCreditLogo?.imageUrl.includes(
                                                "http"
                                            )
                                                ? listing?.dISearchCreditLogo
                                                      ?.imageUrl
                                                : `https://cdn.lakehomes.com/images/mls_logos/${listing?.dISearchCreditLogo?.imageUrl}`
                                        }
                                        alt="MLS Credit"
                                        style={{ maxHeight: "25px" }}
                                    />
                                </div>
                            )}
                        </div>
                    </CardBody>
                </Link>
            </Card>
        </div>
    );
};

export default ListingItem;
