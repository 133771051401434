import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, FormGroup, Alert, Button, Row, Col } from "reactstrap";
import { useForm } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import { useUserLogin } from "../../hooks/login";
import ValidatingField from "../ValidatingField";
import IndeterminateButton from "../IndeterminateButton";
import { ForgotPassword } from "./";
import {
    setUserInfo,
    setStyling,
    removeAssemblies,
    assebliesWithUserData,
    setSavedProperties,
} from "../../reducers/rootReducer";
import AssemblyProvider from "../AssemblyProvider";
import useTrackEvent from "../../hooks/useTrackEvent";
import { isEmptyObject } from "../../support/helpers";
import { useActivityTracking } from "../../hooks/useActivityTracking";
import { removePage } from "../../reducers/pagesReducer";

/**
 * Logs the user in.
 */
const Login = ({ isRegistering, setIsRegistering }) => {
    const dispatch = useDispatch();
    const sessionID = useSelector((state) => state.root.userSession.sessionID);
    const loginForm = useForm({ mode: "all" });
    const {
        handleSubmit,
        formState: { isValid },
        getValues,
    } = loginForm;
    const {
        execute: logIn,
        error,
        isLoading,
        data: loginData,
    } = useUserLogin();
    const { execute: sendActivity } = useActivityTracking();
    const [alertData, setAlertData] = useState(undefined);
    const { trackGTM } = useTrackEvent();

    const onSubmit = async (data) => {
        // Checking if registering and creating a new session id so that we can
        // log a user in if they started registering under the current session ID
        // otherwise there would be an error on the login attempt.
        const sessionId = isRegistering ? uuidv4() : sessionID;
        // Will have to add continuationOf_sessionID here
        await logIn(sessionId, data.username, data.password);
    };

    useEffect(() => {
        if (loginData?.entryDefects || (loginData && !loginData?.session)) {
            const userMessage = loginData?.entryDefects
                ? Object.values(loginData?.entryDefects?.[0])
                : undefined;
            setAlertData({
                color: "warning",
                alertText: userMessage || (
                    <>
                        Sorry, we are unable to verify your account information.
                        Please make sure your username and password are correct
                        and try again.
                    </>
                ),
            });
        } else if (loginData?.session && !isEmptyObject(loginData.user)) {
            setAlertData(undefined);
            dispatch(setUserInfo(loginData.session));
            dispatch(setStyling(loginData.styling));
            dispatch(
                setSavedProperties(
                    loginData?.user?.userProperties?.userProperties
                )
            );
            setIsRegistering(false);
            trackGTM({
                event: `login`,
                action: "click",
                type: "Account Login",
                category: "account",
            });

            // We need to refresh the broker agents so when an entitled user logs in
            // we show inactive agents
            dispatch(removePage({ pageType: "brokerAgents" }));

            // These assemblies contain data about the user and will need to be retrieved again.
            dispatch(removeAssemblies(assebliesWithUserData));

            // We are here because the login attempt did not return a user
            // There is a bug in the DB code that some sessions become corrupt
            // and the login attempt appears successful, but is really not.
        } else if (loginData?.session && isEmptyObject(loginData.user)) {
            const formData = getValues();
            const newSessionId = uuidv4();
            logIn(newSessionId, formData.username, formData.password);
            sendActivity({
                category: "ERROR",
                action: `Issue loggin in. Empty user object.`,
                newSessionId,
                originalSessionId: sessionID,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loginData]);

    useEffect(() => {
        if (error) {
            setAlertData({
                color: "danger",
                alertText: (
                    <>
                        Sorry, we seem to be having issues right now. Please{" "}
                        <Button
                            className="p-0"
                            color="link"
                            onClick={handleSubmit(onSubmit)}
                        >
                            click here to try again.
                        </Button>
                    </>
                ),
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

    return (
        <AssemblyProvider group="USER_ACCOUNT" name="LOGIN">
            {(assembly) => (
                <ForgotPassword>
                    {(toggleResetPassword) => (
                        <>
                            <Alert
                                color={alertData?.color}
                                isOpen={alertData !== undefined}
                                toggle={() => setAlertData(undefined)}
                            >
                                {alertData?.alertText}
                            </Alert>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <Row>
                                    <Col>
                                        <ValidatingField
                                            name="username"
                                            dataTest="username-login"
                                            placeholder="Username"
                                            assembly={assembly}
                                            {...loginForm}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <ValidatingField
                                            type="password"
                                            name="password"
                                            placeholder="Password"
                                            assembly={assembly}
                                            {...loginForm}
                                        />
                                    </Col>
                                </Row>
                                <Row className="align-items-center">
                                    <Col>
                                        <FormGroup className="d-flex">
                                            <Button
                                                className="ms-auto px-2"
                                                color="link"
                                                onClick={() =>
                                                    toggleResetPassword()
                                                }
                                            >
                                                Forgot Password?
                                            </Button>
                                            <IndeterminateButton
                                                type="submit"
                                                className="px-2"
                                                isLoading={isLoading}
                                                onClick={handleSubmit(onSubmit)}
                                                disabled={!isValid}
                                            >
                                                Sign In
                                            </IndeterminateButton>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Form>
                        </>
                    )}
                </ForgotPassword>
            )}
        </AssemblyProvider>
    );
};

export default Login;
