/*
 * ------------------------------------------------------------------------------------
 * DESCRIPTION:
 * ------------------------------------------------------------------------------------
 *      NotFound component
 *
 * ------------------------------------------------------------------------------------
 * VERSION HISTORY: (NOTE: The version variables must be updated manually.)
 * ------------------------------------------------------------------------------------
 *                   Changed By
 *      Date         Initials    Version #  Sub-Version  Notes
 *      -----------  ----------  ---------  -----------  ------------------------------
 *      2022-11-16   BL          22320      1            Re-structured files and renamed to index.js
 *
 */

import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import HeroErrorWrapper from "../HeroErrorWrapper";
import { useNacLocation } from "../../hooks/useNacLocation";

const NotFound = ({ children }) => {
    const { state: { isPageNotFound } = {} } = useNacLocation();
    const { nichetypeSingular } =
        useSelector((state) => state.root.styling) || {};

    return (
        <>
            {isPageNotFound ? (
                <HeroErrorWrapper className="w-100" data-test="page-not-found">
                    <h2 className="h1">Oops!</h2>
                    <p className="fs-3 mb-0">Page Not Found</p>
                    <p className="fs-5">
                        The page you are looking for is on the{" "}
                        {(nichetypeSingular || "").toLowerCase()}. Why aren't
                        you?
                    </p>
                    <div className="d-flex w-100">
                        <Link
                            className="ms-auto btn btn-primary text-decoration-none"
                            to="/"
                        >
                            Find Your {nichetypeSingular} Home
                        </Link>
                    </div>
                </HeroErrorWrapper>
            ) : (
                children
            )}
        </>
    );
};

export default NotFound;
