import React, { useState } from "react";
import useToggle from "../../../hooks/useToggle";
import Verify from "./Verify";
import Request from "./Request";

function ForgotPassword({ children }) {
    const [isResettingPassword, toggleIsResettingPassword] = useToggle(false);
    const [isVerifying, toggleIsVerifying] = useToggle(false);
    const [username, setUsername] = useState(undefined);

    return (
        <>
            {isResettingPassword ? (
                <>
                    {isVerifying ? (
                        <Verify
                            username={username}
                            toggleIsResettingPassword={() =>
                                toggleIsResettingPassword()
                            }
                        />
                    ) : (
                        <Request
                            username={username}
                            setUsername={setUsername}
                            toggleIsVerifying={toggleIsVerifying}
                            toggleIsResettingPassword={() =>
                                toggleIsResettingPassword()
                            }
                        />
                    )}
                </>
            ) : (
                children(toggleIsResettingPassword)
            )}
        </>
    );
}

export default ForgotPassword;
