import React, { useState } from "react";
import { useSelector } from "react-redux";
import Create from "./Create";
import Verify from "./Verify";
import Complete from "./Complete";

const Register = ({ setIsRegistering }) => {
    const isLoggedIn = useSelector((state) => state.root.isLoggedIn);
    const { hasPW, verified } = useSelector((state) => state.root.userSession);
    const [stepNumber, setStepNumber] = useState(
        !isLoggedIn ? 1 : !verified && !hasPW ? 2 : 1
    );
    const [username, setUsername] = useState(null);

    const nextStep = (isComplete = false, callback) => {
        const nextStepNumber = stepNumber === 3 ? 1 : stepNumber + 1;
        setIsRegistering(!isComplete);
        if (callback) callback();
        setStepNumber(nextStepNumber);
    };

    return (
        <>
            {stepNumber === 1 && (
                <Create nextStep={nextStep} setUsername={setUsername} />
            )}
            {stepNumber === 2 && (
                <Verify nextStep={nextStep} username={username} />
            )}
            {stepNumber === 3 && <Complete />}
        </>
    );
};

export default Register;
