import { useCallback, useEffect, useState } from "react";
import PubSub from "pubsub-js";
import { useSelector } from "react-redux";
import { useQueryParams } from "use-query-params";
import {
    locationQueryModel,
    searchQueryModel,
} from "../components/Search/SearchProvider";
import { getQueryKeys } from "../components/Search/helpers";
import { useNacLocation } from "./useNacLocation";

export const useSearchData = () => {
    const { pathname, state: { stateId } = {} } = useNacLocation();
    const stateData = useSelector((state) => state.pages.states?.[stateId]);
    const [locationQuery] = useQueryParams(locationQueryModel);
    const [searchQuery] = useQueryParams(searchQueryModel);
    const { locationQueryKey, searchQueryKey, resultsQueryKey, gisQueryKey } =
        getQueryKeys(locationQuery, searchQuery, pathname);
    // NOTE -- the SearchProvider pulls this data from state using keys. If this pattern changes, we may also need to update
    // the key or path to data in that locatino too
    const rawLocationData = useSelector(
        (state) => state.search.location[locationQueryKey]
    );
    const rawResultsData = useSelector(
        (state) => state.search.results?.[resultsQueryKey]
    );
    const rawGisData = useSelector((state) => state.search.gis?.[gisQueryKey]);
    const selectedRegion = (stateData?.stateRegions || []).find(
        (region) => region.value === locationQuery?.region
    );
    const hasSearched =
        locationQuery?.zip !== undefined || searchQueryKey !== undefined;
    const [resultsData, setResultsData] = useState();
    const [locationData, setLocationData] = useState();
    const [gisData, setGisData] = useState();

    const resetSearchState = useCallback(() => {
        setResultsData();
        setLocationData();
        setGisData();
    }, []);

    // Setting data only when the appropriate state updates.
    useEffect(() => {
        if (rawResultsData) {
            setResultsData(rawResultsData);
        } else if (resultsData && !rawResultsData && !hasSearched) {
            setResultsData();
        }
    }, [rawResultsData, resultsData, hasSearched]);
    useEffect(() => {
        if (rawLocationData) {
            setLocationData(rawLocationData);
        } else if (locationData && !rawLocationData && !hasSearched) {
            setLocationData();
        }
    }, [rawLocationData, locationData, hasSearched]);
    useEffect(() => {
        if (rawGisData) {
            setGisData(rawGisData);
        } else if (gisData && !rawGisData && !hasSearched) {
            setGisData();
        }
    }, [rawGisData, gisData, hasSearched]);

    useEffect(() => {
        const sub = PubSub.subscribe("RESET_SEARCH", () => {
            resetSearchState();
        });
        return () => PubSub.unsubscribe(sub);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        locationQuery,
        locationData,
        searchQuery,
        resultsData,
        gisData,
        selectedRegion,
        hasSearched,
    };
};
