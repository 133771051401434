import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Alert } from "reactstrap";
import { Link } from "react-router-dom";
import { Form, FormGroup } from "reactstrap";
import { useForm } from "react-hook-form";
import ValidatingField from "../ValidatingField";
import { useUser } from "../../hooks/user";
import IndeterminateButton from "../IndeterminateButton";
import { useGetStyling } from "../../hooks/useStyling";
import UpdateEmail from "./UpdateEmail/UpdateEmail";
import AssemblyProvider from "../AssemblyProvider";
import Loading from "../Loading";
import {
    setAssemblies,
    setStyling,
    setUserInfo,
} from "../../reducers/rootReducer";

const ExistingVerify = ({ children }) => {
    const dispatch = useDispatch();
    const { sessionID, verified } = useSelector(
        (state) => state.root.userSession
    );
    const [isVerifying, setIsVerifying] = useState(false);
    const { execute: getStyling, isLoading: isStylingLoading } =
        useGetStyling();
    const { execute, isLoading: isUserLoading } = useUser();
    const isLoading = isUserLoading || isStylingLoading;
    const registerForm = useForm();
    const { handleSubmit, setError } = registerForm;

    const sendCode = async () =>
        await execute(`userID/email/verify`, {
            method: "POST",
            body: JSON.stringify({}),
        });

    const handleStartVerification = async () => {
        setIsVerifying(true);
        sendCode();
        if (sessionStorage.getItem("emailExpiration") < Date.now()) {
            const { assembly: emailRequestAssembly } = await sendCode();
            dispatch(
                setAssemblies({ "VERIFY-EMAIL_REQUEST": emailRequestAssembly })
            );
            // TODO make expiration configurable
            sessionStorage.setItem(
                "emailExpiration",
                Date.now() + 1000 * 60 * 60 * 1.95
            ); // Just under 2 hours from now
        }
    };

    const onSubmit = async (data) => {
        const { reset_code, email } = data;
        const verificationResponse = await execute(
            "userID/email/verify/confirm",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    sessionId: sessionID,
                },
                body: JSON.stringify({ reset_code, email }),
            }
        );
        if (verificationResponse?.entryDefects) {
            verificationResponse.entryDefects.forEach((defect) => {
                const name = Object.keys(defect)[0];
                setError(name, { message: defect[name] });
            });
        } else {
            const stylingResponse = await getStyling(sessionID);
            dispatch(setStyling(stylingResponse));
            dispatch(setUserInfo(stylingResponse.userInfo4Session));
            setIsVerifying(false);
        }
    };

    return (
        <AssemblyProvider
            group="VERIFY-EMAIL_REQUEST"
            name="VERIFY-EMAIL_REQUEST"
            ignoreCache
        >
            {(assembly, assemblyLoading) => (
                <>
                    {isLoading && assemblyLoading && <Loading />}
                    {!verified && !isVerifying && (
                        <Alert color="warning">
                            <div>
                                You have not verified your email address. Please{" "}
                                <Link
                                    to="/account/verify"
                                    onClick={() => handleStartVerification()}
                                >
                                    Click Here
                                </Link>{" "}
                                to verify.
                            </div>
                        </Alert>
                    )}
                    {!isVerifying && children}
                    {isVerifying && !isLoading && (
                        <UpdateEmail>
                            {({ toggleIsUpdating }) => (
                                <>
                                    <div>
                                        <p>
                                            A code has been sent to your e-mail
                                            account. Please enter the code to
                                            verify your e-mail. If you no longer
                                            have access to this e-mail, click
                                            here to{" "}
                                            <Link
                                                onClick={() =>
                                                    toggleIsUpdating()
                                                }
                                            >
                                                {" "}
                                                Update Email.
                                            </Link>
                                        </p>
                                        <p>
                                            <strong>
                                                Please use the same device and
                                                browser for account
                                                verification.
                                            </strong>
                                        </p>
                                    </div>
                                    <Form onSubmit={handleSubmit(onSubmit)}>
                                        <Row>
                                            <Col>
                                                <ValidatingField
                                                    name="email"
                                                    assembly={assembly}
                                                    {...registerForm}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <ValidatingField
                                                    name="reset_code"
                                                    assembly={assembly}
                                                    {...registerForm}
                                                />
                                            </Col>
                                        </Row>
                                        <FormGroup className="d-flex">
                                            <IndeterminateButton
                                                color="link"
                                                className="ms-auto"
                                                isLoading={isUserLoading}
                                                onClick={() => sendCode()}
                                            >
                                                Resend Code
                                            </IndeterminateButton>
                                            <IndeterminateButton
                                                type="submit"
                                                className="btn btn-primary "
                                                value="Sign In"
                                                isLoading={isUserLoading}
                                            >
                                                Verify
                                            </IndeterminateButton>
                                        </FormGroup>
                                    </Form>
                                </>
                            )}
                        </UpdateEmail>
                    )}
                </>
            )}
        </AssemblyProvider>
    );
};

export default ExistingVerify;
