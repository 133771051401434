import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Modal, ModalBody, ModalHeader, Form, Input, Button } from "reactstrap";
import { useForm } from "react-hook-form";
import { getDomain } from "../../support/helpers";
import CdnImage from "../CdnImage";

/**
 * Displays a "coming soon" modal if the REACT_APP_COMING_SOON env variable is set.
 * The purpose of this is for beta testing in production.
 * @param {*} children
 */
const ComingSoonModal = ({ comingSoonValue = "", children }) => {
    const [domain, password] = comingSoonValue.split("|");
    const [showModal, setShowModal] = useState(false);
    const { nicheName } = useSelector((state) => state.root.styling || {});
    const {
        handleSubmit,
        register,
        formState: { isValid },
    } = useForm({ mode: "onChange" });
    const { ref, ...restOfForm } = register("password", {
        require: true,
        validate: (value) => value === password,
    });

    useEffect(() => {
        let hasSession = sessionStorage.getItem("comingSoon");
        const host = getDomain(location.hostname);
        if (!hasSession && host.includes(domain) && password) {
            setShowModal(true);
        }
    }, []);

    const onSubmit = () => {
        setShowModal(false);
        sessionStorage.setItem("comingSoon", "true");
    };

    if (!showModal) return <>{children}</>;
    return (
        <>
            {children}
            <Modal isOpen={showModal} centered backdropClassName="dark">
                <ModalHeader>Coming Soon!</ModalHeader>
                <ModalBody>
                    <div className="d-flex justify-content-center mb-3">
                        <CdnImage
                            style={{ height: "60px" }}
                            src="/header-logo.png"
                            alt={nicheName}
                        />
                    </div>
                    <Form onSubmit={handleSubmit(onSubmit)} className="d-flex">
                        <Input
                            style={{
                                borderBottomRightRadius: 0,
                                borderTopRightRadius: 0,
                            }}
                            name="password"
                            type="text"
                            innerRef={ref}
                            {...restOfForm}
                        />
                        <Button
                            style={{
                                borderBottomLeftRadius: 0,
                                borderTopLeftRadius: 0,
                            }}
                            type="submit"
                            color="primary"
                            disabled={!isValid}
                        >
                            Enter
                        </Button>
                    </Form>
                </ModalBody>
            </Modal>
        </>
    );
};

export default ComingSoonModal;
