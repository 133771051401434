import React, { createContext, useContext, useReducer } from "react";
import withSSR from "../HOC/withSSR";

export const initialState = {};

const mapContext = createContext(undefined);
const { Provider } = mapContext;

const SET_MAP = "SET_MAP";
const REMOVE_MAP = "REMOVE_MAP";

export const setMap = (map, maps) => ({ type: SET_MAP, map, maps });
export const removeMap = () => ({ type: REMOVE_MAP });

export const reducer = (state, action) => {
    switch (action.type) {
        case SET_MAP:
            return { ...state, map: action.map, maps: action.maps };
        case REMOVE_MAP:
            delete state.map;
            delete state.maps;
            return state;
        default:
            return state;
    }
};

export const useMapContext = () => {
    const context = useContext(mapContext);
    if (context === undefined) {
        throw new Error("useMapContext can only be used inside MapProvider");
    }
    return context;
};

const MapProvider = ({ initState, children }) => {
    const [state, dispatch] = useReducer(reducer, initState);

    if (process.env.NODE_ENV !== "production") console.log("MAP STATE", state);
    return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

//Adding initial state to provider
const MapProviderSSR = withSSR(MapProvider, initialState, "MapProvider");
export { mapContext, MapProviderSSR as MapProvider };
