import React, { memo } from "react";
import { FaRegHandPointer } from "react-icons/fa";
import Icon from "../../Icon";

const MapToolTip = memo(({ stateData }) => {
    return (
        <div className="w-100">
            <div className="mx-5 position-relative">
                {stateData && (
                    <div
                        className="position-absolute w-100 bg-white border rounded p-3 d-flex"
                        style={{ zIndex: 300 }}
                    >
                        <Icon
                            className="state-icon me-3 text-primary"
                            icon={stateData.stateCode}
                            size={64}
                        />
                        <div>
                            <strong>{stateData.stateName}</strong>
                            {stateData.isStateServed ? (
                                <>
                                    <div>{stateData.listingsInfo}</div>
                                    <div>{stateData.nicheItemsInfo}</div>
                                </>
                            ) : (
                                <div>{stateData.stateStatus}</div>
                            )}
                        </div>
                    </div>
                )}
                <div className="mt-3 w-100 text-center">
                    Use our interactive map <FaRegHandPointer />
                </div>
            </div>
        </div>
    );
});

export default MapToolTip;
