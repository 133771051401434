import { v4 as uuidv4 } from "uuid";
import { matchPath } from "react-router-dom";

/**
 * Asynchronously fetches the url and params
 * @param {string} url - URL to fetch
 * @param {Object} params - Params to pass to fetch
 * @param {number} [retries=3] - Number of retries
 *
 * @returns {Object} response
 */
export const fetchOverlord = async (url, params, retries = 3) => {
    if (!params?.headers?.["Niche-Domain"] && location) {
        params.headers = {
            ...params.headers,
            "Niche-Domain": parseDomain(location.hostname),
        };
    }
    const result = await fetch(url, { ...params });
    if (result.status === 204) return;
    let response = await result.json();
    if (
        result.status.toString().startsWith("2") ||
        (result.status === 503 && response?.mode === "maintenance")
    ) {
        return response;
    } else if (
        result.status === 401 &&
        (response.message === "Session is invalid." ||
            response.message?.SYSTEM_ERROR__SESSION_INVALID ===
                "Session is invalid.") &&
        retries > 1
    ) {
        return await fetchOverlord(
            url,
            { ...params, headers: { ...params?.headers, sessionId: uuidv4() } },
            retries - 1
        );
    } else if (
        result.status === 401 ||
        result.status === 403 ||
        response?.entryDefects
    ) {
        return response;
    } else if (retries > 1) {
        return await fetchOverlord(url, params, retries - 1);
    } else {
        throw new Error("Failed after 3 attempts.");
    }
};

/**
 * Grabs the correct domain
 * @param {string} domain - Domain to check
 *
 * @returns {string} domain
 */
export const getDomain = (domain) => {
    if (domain === "localhost") {
        return process.env.REACT_APP_NICHE_THEME
            ? `${process.env.REACT_APP_NICHE_THEME}.com`
            : "lakehomes.com";
    }
    return domain;
};

/**
 * Parses the domain
 * @param {string} domain - Domain to parse
 *
 * @returns {string} parsed domain
 */
export const parseDomain = (domain) => {
    try {
        // Punt the port ...if the domain has one.
        if (domain.includes(":")) {
            domain = domain.split(":").shift();
        }
        // remove the subdomain, if there is one and return.
        const parts = domain.split(".");
        if (parts.length > 2 && parts.length < 4) {
            parts.shift();
            return parts.join(".");
            // If the domain is an IP address, Force 'localhost'
            // If we are local, check for the THEME env var
        } else {
            return getDomain(parts.length === 4 ? "localhost" : domain);
        }
    } catch (e) {
        return domain;
    }
};

/**
 * Grabs the CDN base path
 * @param {(string|boolean)} nicheDomain - Domain to check
 * @param {string=} host
 *
 * @returns {string} parsed domain
 */
export const getCdnBasePath = (nicheDomain, host) => {
    try {
        const cdnPath = getConfigItem("REACT_APP_CDN_BASE_PATH");
        const cdnHost =
            getConfigItem("REACT_APP_CDN_BASE_URL") ||
            host ||
            getSafeHostname() ||
            "https://cdn.lakehomes.com";
        return nicheDomain
            ? `${cdnHost}${cdnPath}/images/${nicheDomain}`
            : `${cdnHost}${cdnPath}`;
    } catch {
        return "https://cdn.lakehomes.com";
    }
};

/**
 * Gets the hostname
 *
 * @returns {string} hostname
 */
export const getSafeHostname = () => {
    if (typeof window !== "undefined" && window !== null) {
        return window.location.hostname;
    } else {
        return undefined;
    }
};

/**
 * Gets CDN URL
 * @param {string} reqHost
 * @param {string} envBaseUrl
 *
 * @returns {string} CDN URL
 */
export const getCdnUrl = (reqHost, envBaseUrl) => {
    if (envBaseUrl) {
        return envBaseUrl;
    } else if (!reqHost.includes("localhost")) {
        const noPortHost = reqHost.split(":")[0]; // removing port if there is one.
        const parts = noPortHost.split(".");
        if (parts.length > 2) parts.shift(); // removing first part of subdomain if it has more than 2 parts
        parts.unshift("cdn"); // adding cdn as the first item in the parts array
        return `https://${parts.join(".")}`;
    }
};

/**
 * Uses a timer to return a function
 * @param {Function} func - Function to return
 * @param {number} wait - Time to wait
 * @param {boolean} immediate - Boolean representing whether to return immediately
 *
 * @returns {Function} function
 */
export const debounce = (func, wait, immediate) => {
    var timeout;
    return function () {
        var context = this,
            args = arguments;
        var later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
};

/**
 * Gets the base URL
 * @param {string} host - Host to check
 *
 * @returns {string} base URL
 */
export const getBaseUrl = (host) => {
    const url = getConfigItem("REACT_APP_API_BASE_URL") || host;
    const path = getConfigItem("REACT_APP_API_BASE_PATH");
    let apiUrl = `${url}${path}`;
    if (!apiUrl.includes("http")) {
        apiUrl = apiUrl.includes("localhost")
            ? `http://${apiUrl}`
            : `https://${apiUrl}`;
    }
    return apiUrl;
};

/**
 * Gets the share URL
 *
 * @returns {string} share URL
 */
export const getShareUrl = () => {
    if (typeof window !== "undefined" && window !== null) {
        return window.location.hostname === "localhost"
            ? `https://testing4.lakehomes.com${window.location.pathname}`
            : window.location.href;
    } else {
        return undefined;
    }
};

/**
 * Gets the map key
 *
 * @returns {string} Map API Key
 */
export const getMapKey = () => {
    // if we do not provide a key, this will return undefined which will render the development version of GoogleMaps
    try {
        return process.env.mapApiKey || window.mapApiKey;
    } catch (e) {
        console.log(
            "No Map Key Found. Unable to retrieve it from configuration."
        );
        return undefined;
    }
};

/**
 * Gets the config item
 * @param {string} name - Name of the config item
 *
 * @returns {string} config item
 */
export const getConfigItem = (name) => {
    // if we do not provide a key, this will return undefined which will render the development version of GoogleMaps
    try {
        return (
            (typeof window !== "undefined" &&
                window !== null &&
                window[name]) ||
            process.env[name] ||
            process.env[`REACT_APP_${name}`] ||
            undefined
        );
    } catch (e) {
        console.log(
            `No property ${name} Found. Unable to retrieve it from configuration.`
        );
        return undefined;
    }
};

/**
 * Builds the path (adds '/' if not present)
 * @param {string} path - Path to build
 *
 * @returns {string} built path
 */
export const buildPath = (path) => (path.startsWith("/") ? path : `/${path}`);

/**
 * Gets the public image asset path
 * @param {string} prefix - Prefix for the image
 * @param {string} image - Image to get
 *
 * @returns {string} public image asset path
 */
export const getPublicImageAssetPath = (prefix, image) =>
    `/assets/${prefix || "lakehomes"}_${image}`;

/**
 * Builds query params
 * @param {Object} params - Query params to build
 *
 * @returns {string} query params string
 */
export const buildQueryParams = (params) => {
    let query = [];
    Object.keys(params).forEach((k) => {
        if (params[k]) {
            const paramValue =
                typeof params[k] === "object"
                    ? JSON.stringify(params[k])
                    : params[k];
            query.push(`${k}=${paramValue}`);
        }
    });
    return query.length > 0 ? `?${query.join("&")}` : "";
};

/**
 * Merges two arrays and the item in array 'b' will replace the item in array 'a'
 * @param {*[]} a - Array A to merge
 * @param {*[]} b - Array B to merge
 * @param {string} prop - the property to compare
 *
 * @returns {*{}} Merged array
 */
export const merge = (a, b, prop) => {
    const reduced = a.filter(
        (aitem) => !b.find((bitem) => aitem[prop] === bitem[prop])
    );
    return reduced.concat(b);
};

/**
 * Identifies whether the URL is external
 * @param {string} url - URL to check
 *
 * @returns {(string|boolean)} external URL or boolean
 */
export const isExternalUrl = (url) => {
    if (typeof window !== "undefined" && window !== null) {
        var host = window.location.hostname;

        var linkHost = (function (url) {
            if (/^https?:\/\//.test(url)) {
                // Absolute URL.
                // The easy way to parse an URL, is to create <a> element.
                // @see: https://gist.github.com/jlong/2428561
                var parser = document.createElement("a");
                parser.href = url;

                return parser.hostname;
            } else {
                // Relative URL.
                return window.location.hostname;
            }
        })(url);

        return host !== linkHost;
    } else {
        return false;
    }
};

/**
 * Formats the currency in USD
 * @param {number} amount - Amount to format
 *
 * @returns {number} formatted currency
 */
export const formatCurrency = (amount) => {
    return new Intl.NumberFormat("en-US", {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
        style: "currency",
        currency: "USD",
    }).format(amount);
};

/**
 * Appends nLetter to the end of a number
 * @example 1000 = 1k or 1000000 = 1M
 * @param {number} num - Number to format
 * @param {number} [digits = 1] - Number of digits to show
 *
 * @returns {string} formatted number
 */
export const nFormatter = (num, digits = 1) => {
    var si = [
        { value: 1, symbol: "" },
        { value: 1e3, symbol: "K" },
        { value: 1e6, symbol: "M" },
        { value: 1e9, symbol: "G" },
        { value: 1e12, symbol: "T" },
        { value: 1e15, symbol: "P" },
        { value: 1e18, symbol: "E" },
    ];
    var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var i;
    for (i = si.length - 1; i > 0; i--) {
        if (num >= si[i].value) {
            break;
        }
    }
    return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
};

/**
 * Gets the average geolocation
 * @param {Object} coords
 *
 * @returns {Object} average geolocation
 */
export const averageGeolocation = (coords) => {
    if (coords.length === 0) {
        return { lat: 37.0902, lng: -95.7129 };
    } else if (coords.length === 1) {
        return { lat: coords[0].latitude, lng: coords[0].longitude };
    }

    let x = 0.0;
    let y = 0.0;
    let z = 0.0;

    for (let coord of coords) {
        let latitude = (coord.latitude * Math.PI) / 180;
        let longitude = (coord.longitude * Math.PI) / 180;
        x += Math.cos(latitude) * Math.cos(longitude);
        y += Math.cos(latitude) * Math.sin(longitude);
        z += Math.sin(latitude);
    }

    let total = coords.length;

    x = x / total;
    y = y / total;
    z = z / total;

    let centralLongitude = Math.atan2(y, x);
    let centralSquareRoot = Math.sqrt(x * x + y * y);
    let centralLatitude = Math.atan2(z, centralSquareRoot);

    return {
        lat: (centralLatitude * 180) / Math.PI,
        lng: (centralLongitude * 180) / Math.PI,
    };
};

/**
 * Replaces instances of the provided string
 * @param {string} str
 * @param {string} find
 * @param {string} replace
 *
 * @returns {string} replaced string
 */
export const replaceAll = (str, find, replace) => {
    return str.replace(new RegExp(find, "g"), replace);
};

/**
 * Sets value to lowercase and replaces all provided
 * @param {string} value
 * @param {string} replaceChar
 * @param {string} withChar
 *
 * @returns {string} replaced string
 */
export const toLowerAndReplace = (value, replaceChar = " ", withChar = "-") => {
    try {
        return replaceAll(value.toLowerCase(), replaceChar, withChar);
    } catch {
        return value;
    }
};

/**
 * Checks if two object keys are equal
 * @param {Object} obj1
 * @param {Object} obj2
 *
 * @returns {boolean} Represents if the keys are equal
 */
export const objectKeysEqual = (obj1, obj2) => {
    return arraysEqual(Object.keys(obj1), Object.keys(obj2));
};

/**
 * Checks if two arrays are equal
 * it is checking if there is at least one element that is present in both arrays
 * @param {*[]} _arr1
 * @param {*[]} _arr2
 *
 * @returns {boolean} Represents if the given arrays are equal
 */
export const arraysEqual = (_arr1, _arr2) => {
    if (
        !Array.isArray(_arr1) ||
        !Array.isArray(_arr2) ||
        _arr1.length !== _arr2.length
    )
        return false;
    const arr1 = _arr1.concat().sort();
    const arr2 = _arr2.concat().sort();
    const found = arr1.find((arr1Item) =>
        arr2.find((arr2Item) => arr1Item === arr2Item)
    );
    return found !== undefined;
};

/**
 * Omits keys
 * @param {Object} obj
 * @param {string} omitKeys
 *
 * @returns {Object} Object with omitted keys
 */
export function omit(obj, omitKeys) {
    const result = {};
    Object.keys(obj).forEach((key) => {
        if (omitKeys.indexOf(key) === -1) {
            result[key] = obj[key];
        }
    });
    return result;
}

/**
 * Formats a URL link
 * @param {string} rawUrl
 *
 * @returns {string} formatted URL
 */
export const formatLinkTo = (rawUrl) => {
    try {
        const url = new URL(rawUrl);
        return url.pathname;
    } catch (e) {
        return rawUrl;
    }
};

/**
 * Parses URL params from a string to an object
 * @param {string} search search query '?key=value'
 *
 * @returns {Object} Object with query param keys and values
 */
export const parseUrlParams = (search) => {
    try {
        return search
            .substr(1)
            .split("&")
            .reduce((a, b) => {
                let [key, val] = b.split("=");
                a[key] = val;
                return a;
            }, {});
    } catch (e) {
        return {};
    }
};

/**
 * Retrieves the item needed from an array
 * @param {*[]} array variable
 * @param {number} [item=0] index number
 *
 * @returns {*} item from array
 */
export const arrayResolver = (array, item = 0) =>
    array && Array.isArray(array) && array.length > 0 ? array[item] : undefined;

/**
 * Get assembly by group
 * @param {*} assembly
 * @param {*} group
 * @returns array
 */
export const getAssembliesByGroup = (assembly, group) => {
    const assemblyGroup = assembly
        ? assembly?.definition.filter((item) => item.gROUP === group)
        : undefined;
    return assembly?.data
        ? assemblyGroup.map((item) => {
              const thing = {
                  ...item,
                  fieldInfo: {
                      ...item.fieldInfo,
                      default: assembly.data[item.id] || undefined,
                  },
              };
              return thing;
          })
        : assemblyGroup;
};

/**
 * Get assembly by item name
 * @param {*} assembly
 * @param {*} itemName
 *
 * @returns array
 */
export const getAssemblyItem = (assembly, itemName) => {
    try {
        return assembly.find((assemblyItem) => assemblyItem.id === itemName);
    } catch {
        return undefined;
    }
};

/**
 * Gets the label by the value
 * @param {*[]} list
 * @param {*} value
 * @param {*} defaultValue
 *
 * @returns {*} label
 */
export const getLabelByValue = (list, value, defaultValue) => {
    try {
        if (!list || !value) return defaultValue;
        const item = list.find((item) => item.value === value);
        return item.label;
    } catch {
        return defaultValue || undefined;
    }
};

/**
 * Takes an array of data and returns an object
 * @param {Object[]} dataItems array
 *
 * @returns {Object} object with array items
 */
export const diToObject = (dataItems) => {
    const dataObject = {};
    dataItems.forEach((item) => {
        dataObject[item.id] = { value: item.value, label: item.label };
    });
    return dataObject;
};

/**
 * Checks if object is empty
 * @param {Object} obj object
 *
 * @returns {boolean} Boolean representing if object is empty
 */
export const isEmptyObject = (obj) => {
    let name;
    for (name in obj) {
        return false;
    }
    return true;
};

/**
 * Checks if object has property values
 * @param {Object} obj object
 *
 * @returns {boolean} Boolean representing if object has property values
 */
export const hasPropValues = (obj) => {
    let hasValue = false;
    for (const value of Object.values(obj)) {
        if (value) {
            hasValue = true;
            break;
        }
    }
    return hasValue;
};

/**
 * Checks whether item is an object
 * @param {*} obj - Item to test
 *
 * @returns {boolean} Boolean representing if item is an object
 */
export const isObject = (obj) => {
    return typeof obj === "object" && !Array.isArray(obj) && obj !== null;
};

/**
 * Checks whether object has filters
 * @param {Object[]} assembly
 * @param {string} id
 * @param {Function} getValues
 *
 * @returns {Object} Object with validation rules
 */
export const buildValidation = (assembly, id, getValues) => {
    try {
        const { lengthInfo, validationBundle, requiredInfo, relativeInfo } =
            assembly.find((item) => item.id === id);
        const validations = {};

        if (getValues && relativeInfo) {
            relativeInfo.forEach((relativeItem) => {
                validations.validate = (value) => {
                    return (
                        value === getValues()[relativeItem.relative] ||
                        relativeItem?.relativeInvalidDesc
                    );
                };
            });
        }

        if (requiredInfo && requiredInfo.required === 1) {
            validations.required = requiredInfo.requiredInvalidDesc;
        }

        if (lengthInfo) {
            if (lengthInfo.lengthMax) {
                validations.maxLength = {
                    value: lengthInfo.lengthMax,
                    message: lengthInfo.lengthMaxInvalidDesc,
                };
            }
            if (lengthInfo.lengthMin) {
                validations.minLength = {
                    value: lengthInfo.lengthMin,
                    message: lengthInfo.lengthMinInvalidDesc,
                };
            }
        }

        if (validationBundle && validationBundle.length === 1) {
            const vb = validationBundle[0];
            if (
                vb.validationType === "CONSUMER" &&
                vb.validationCore === "DATE"
            ) {
            } else if (
                vb.validationType !== "SERVER" &&
                vb?.validationCores?.validationCoreJS
            ) {
                validations.pattern = {
                    value: new RegExp(vb.validationCores.validationCoreJS),
                    message: vb.validationInvalidDesc,
                };
            }
        }

        return validations;
    } catch (err) {
        return {};
    }
};

/**
 * Shuffles an array and returns a copy
 * @param {*[]} array
 *
 * @returns {*[]} shuffled array
 */
export const shuffleArray = (array) => {
    const arrayCopy = [...array];
    var currentIndex = arrayCopy.length,
        temporaryValue,
        randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        // And swap it with the current element.
        temporaryValue = arrayCopy[currentIndex];
        arrayCopy[currentIndex] = arrayCopy[randomIndex];
        arrayCopy[randomIndex] = temporaryValue;
    }

    return arrayCopy;
};

/**
 * Pans to point and zoom
 * @param {Object} map
 * @param {number} zoom
 * @param {Object} point Contains lat and lng
 */
export const panToPointAndZoom = (map, zoom, point) => {
    map.systemZoomChange = true; // This is a flag that will allow us to determine when the user changes the zoom;
    map.panTo(point);
    map.setZoom(zoom);
};

/**
 * Fits the boundary to the markers.
 * @param {Object} map
 * @param {Object} maps
 * @param {Object} markers
 * @param {number} zoom
 */
export const fitBoundsToMarkers = (map, maps, markers, zoom, shouldPan) => {
    map.systemZoomChange = true; // This is a flag that will allow us to determine when the user changes the zoom;
    const bounds = new maps.LatLngBounds();
    markers.forEach((marker) => {
        bounds.extend({ lat: marker.latitude, lng: marker.longitude });
    });
    if (!zoom) {
        map.fitBounds(bounds, 0);
    } else if (shouldPan) {
        map.panTo(bounds.getCenter());
    }
};

/**
 * Fits boundary to a circle
 * @param {Object} map
 * @param {Object} maps
 * @param {Object} circles
 * @param {number} zoom
 */
export const fitBoundsToCircle = (map, maps, circles, zoom, shouldPan) => {
    map.systemZoomChange = true; // This is a flag that will allow us to determine when the user changes the zoom;
    const bounds = new maps.LatLngBounds();
    circles.forEach((circle) => {
        bounds.union(circle.getBounds());
    });
    if (!zoom) {
        map.fitBounds(bounds, 0);
    } else if (shouldPan) {
        map.panTo(bounds.getCenter());
    }
};

/**
 * Gets the element's height
 * @param {string[]} els
 *
 * @returns {number} total height of elements
 */
export const getElementHeight = (
    els = ["header-container", "search-container", "footer-container"]
) => {
    let totalHeight = 0;
    if (
        typeof document !== "undefined" &&
        document !== null &&
        document.getElementById
    ) {
        els.forEach((el) => {
            const element = document.getElementById(el);
            return (totalHeight =
                totalHeight + (element ? element.offsetHeight : 0));
        });
    }
    return totalHeight;
};

/**
 * Checks whether the element's height is visible
 * @param {Object} el
 *
 * @returns {number} element's visible height
 */
export const elHeightVisible = (el) => {
    if (!el) return 0;
    try {
        const rect = el.getBoundingClientRect();
        if (rect) {
            const elH = el.offsetHeight,
                H = window.innerHeight,
                r = rect,
                t = r.top,
                b = r.bottom;
            return Math.max(0, t > 0 ? Math.min(elH, H - t) : Math.min(b, H));
        } else return 0;
    } catch (e) {
        return 0;
    }
};

/**
 * Check if element is in the viewport
 * @param {Object} el
 *
 * @returns {boolean} boolean representing whether element is in viewport
 */
export const inViewport = (el) => {
    if (!el) return false;
    try {
        const rect = el.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <=
                (window.innerHeight ||
                    document.documentElement
                        .clientHeight) /* or $(window).height() */ &&
            rect.right <=
                (window.innerWidth ||
                    document.documentElement
                        .clientWidth) /* or $(window).width() */
        );
    } catch (e) {
        return false;
    }
};

/**
 * Checks if image exists
 * @param {string} image_url
 *
 * @returns {boolean} boolean representing whether image exists
 */
export const imageExists = async (image_url) => {
    try {
        const res = await fetch(image_url, { method: "HEAD" });
        return res.ok ? true : false;
    } catch {
        return false;
    }
};

/**
 * Gets the page type
 * @param {Object} params
 * @param {string} isPage
 * @param {number} agentId
 *
 * @returns {string} page type
 */
export const getPageType = (params, isPage, agentId) => {
    let pageType;
    const {
        state: stateParam,
        niche: nicheParam,
        three: thirdParam,
    } = params || {};

    if (nicheParam === "offmarket") pageType = "offmarket";
    else if (thirdParam) {
        const splitThree = thirdParam && (thirdParam.split("-") || undefined);
        let lastItem, secondLastItem;
        if (splitThree) {
            lastItem = splitThree[splitThree.length - 1];
            secondLastItem = splitThree[splitThree.length - 2];
        }
        if (
            splitThree &&
            splitThree.length > 1 &&
            RegExp(".*[0-9].*").test(lastItem) &&
            RegExp("^[A-Za-z]+$").test(secondLastItem)
        ) {
            pageType = "listing";
        } else if (
            thirdParam &&
            RegExp("^([^0-9]*)$").test(thirdParam) &&
            agentId
        ) {
            pageType = "agent";
        } else if (thirdParam && isNaN(parseInt(thirdParam))) {
            pageType = "development";
        } else pageType = "listing";
    } else if (nicheParam) pageType = "niche";
    //TODO: change this to nicheItem
    else if (stateParam) {
        if (stateParam === "agents") {
            pageType = "agents";
        } else if (stateParam === "account") {
            pageType = "account";
        } else if (stateParam === "search") {
            pageType = "search";
        } else {
            pageType = "state";
        }
    } else pageType = "index";

    return isPage ? isPage === pageType : pageType;
};

/**
 * Gets the page type
 * @param {string} tagname
 * @param {string} attr1Name
 * @param {string} attr1Value
 * @param {string} attr2Name
 * @param {string} attr2Value
 */
export const buildOrUpdateTag = (
    tagname,
    attr1Name,
    attr1Value,
    attr2Name,
    attr2Value
) => {
    const tag = document.querySelector(
        `${tagname}[${attr1Name}="${attr1Value}"]`
    );
    if (tag) {
        tag.setAttribute(attr2Name, attr2Value);
    } else {
        const element = document.createElement(tagname);
        element.setAttribute(attr1Name, attr1Value);
        element.setAttribute(attr2Name, attr2Value);
        document.head.append(element);
    }
};

/**
 * Copies to the clipboard
 * @param {string} text - text to be copied
 */
export const copyToClipboard = async (text) => {
    await navigator.clipboard.writeText(text);
};

/**
 * Checks if value is a string or number
 * @param {(string|number)} value
 *
 * @returns {string} the passed values type
 */
export const isStringOrNumber = (value) =>
    typeof value === "string" || typeof value === "number" ? value : "";

/**
 *
 * @param {string} singularWord Singlual version of the word.
 * @param {array} count Array of items that determine if the word is singular or plural
 *
 * @returns {string} A singular or plural word.
 */
export const makePlural = (singularWord, pluralWord, count) =>
    count == 1 ? singularWord : pluralWord;

/**
 * Helper function to determine if the page is offmarket
 * @param {string} pathname
 * @returns {boolean}
 */
export const isOffMarketPage = (pathname) => {
    const path =
        pathname ||
        (typeof window !== "undefined" &&
            window !== null &&
            window.location.pathname);
    return path
        ? !!matchPath(path, {
              path: "/:s/offmarket/:n",
              exact: true,
          })
        : false;
};

/**
 * Prepends the domain to the link if the link starts with '/info/'.
 * @param {string} origin - The origin domain.
 * @param {string} link - The link to be modified.
 * @returns {string} The modified link with the origin domain prepended if the link starts with '/info/', otherwise returns the original link.
 */
export const prependDomain = (origin, link) => {
    if (link.startsWith("/info/")) {
        link = `${origin}${link}`;
        return link;
    } else {
        return link;
    }
};

/**
 * Capitolize first letter of string
 * @param {*} string
 * @returns {string} String with first letter capitolized
 */
export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

/**
 * Removes parent lakes and lakes with no listing from niche
 * item list then returns an array of nicheItemIds.
 * @param {*} nicheItemList
 * @returns {list} nichItemIds
 */
export const filterNicheItemToIds = (nicheItemList) => {
    try {
        return nicheItemList
            .filter(
                (item) => !item?.more?.hasChildren && item?.more?.listings > 0
            ) // Removing parent items and items with 0 listings
            .map((item) => item.more.id); // reducing the list to Ids
    } catch (e) {
        return [];
    }
};

/**
 * Checks window.locationState for the property with the safety
 * check for the window object.
 */
export const resolveLocationStateItem = (property) => {
    if (
        typeof window !== "undefined" &&
        window !== null &&
        window?.locationState?.[property]
    ) {
        return window.locationState[property];
    }
    return undefined;
};

/**
 * Transforms a number into a string with commas.
 * @param {number} number A number
 * @returns {string} Number as string with commas
 */
export const numberWithCommas = (number) => {
    try {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } catch (e) {
        return number;
    }
};

/**
 * Transforms a string into a number.
 * @param {string} value A strin number ex. 1,200
 * @returns {number} Number
 */
export const stringToNumber = (value) => {
    try {
        return parseInt(String(value).replaceAll(",", ""));
    } catch (e) {
        return value;
    }
};

/**
 * Formats niche information data by extracting specific properties and labels.
 *
 * @param {Object} data - The niche information data to be formatted.
 * @returns {Object[]} An array of formatted data objects.
 */
export const formatNicheInformationData = (data) => {
    let result = [];
    if (
        data.counties &&
        Array.isArray(data.counties) &&
        data.counties.length > 0
    ) {
        result.push({
            id: "counties",
            label: "Counties:",
            value: data.counties.map((county) => county.label).join(", "),
        });
    }

    if (data.fish && Array.isArray(data.fish) && data.fish.length > 0) {
        result.push({
            id: "fish",
            label: "Fish:",
            value: data.fish.map((fish) => fish.label).join(", "),
        });
    }

    const keysToExclude = ["counties", "fish", "condos", "sailing_clubs"];
    Object.entries(data)
        .filter(
            ([key, value]) =>
                !keysToExclude.includes(key) &&
                value !== undefined &&
                value !== null &&
                value !== ""
        )
        .forEach(([key, value]) => {
            result.push({
                id: key,
                label: `${
                    key.replace(/_/g, " ").charAt(0).toUpperCase() +
                    key.slice(1).replace(/_/g, " ")
                }:`,
                value: value,
            });
        });

    if (data?.condos !== "hide") {
        result.push({
            id: "condos",
            value: `Condos available (${data.condos})`,
        });
    }

    if (data?.sailing_clubs !== "hide") {
        result.push({
            id: "sailing_clubs",
            value: `Sailing clubs (${data.sailing_clubs})`,
        });
    }
    return result;
};

/**
 * Processes information content
 *
 * @param {Object[]} content - The content to be processed.
 * @returns {Object} An object with processed info content.
 */
export const processInfoContent = (content) => {
    let defaultValues = content?.reduce((acc, item) => {
        if (
            (item.id === "condos" || item.id === "sailing_clubs") &&
            typeof item.value === "string"
        ) {
            acc[item.id] = item.value.includes("No") ? "No" : "Yes";
        } else {
            if (item.id === "periodic_events") {
                acc["notable_events"] = item.value;
            } else {
                acc[item.id] = item.value;
            }
        }
        return acc;
    }, {});

    if (!("condos" in defaultValues)) {
        defaultValues.condos = "hide";
    }

    if (!("sailing_clubs" in defaultValues)) {
        defaultValues.sailing_clubs = "hide";
    }

    if (defaultValues?.counties) {
        const countyNames = defaultValues.counties.split(", ");
        defaultValues.counties = countyNames.map((name) => ({
            label: name,
            value: name,
        }));
    }

    if (defaultValues?.fish) {
        const fishNames = defaultValues.fish.split(", ");
        defaultValues.fish = fishNames.map((name) => ({
            label: name,
            value: name,
        }));
    }
    return defaultValues;
};
