import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import { getBaseUrl, parseDomain } from "../support/helpers";

/**
 * Creates RTK 'useQuery' hooks for the endpoints defined in the 'endpoints'
 * object.
 */
export const dataSystemsAPI = createApi({
    baseQuery: retry(
        fetchBaseQuery({
            baseUrl: getBaseUrl(),
            prepareHeaders: (headers, { getState }) => {
                headers.set("Content-Type", "application/json");
                headers.set("sessionId", getState().root.userSession.sessionID);
                headers.set("Niche-Domain", parseDomain(location.hostname));
                return headers;
            },
        }),
        { maxRetries: 3 }
    ),
    tagTypes: [],
    endpoints: () => ({}),
});
