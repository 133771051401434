import { useActivityTracking } from "./useActivityTracking";

const useExecuteScript = () => {
    const { execute } = useActivityTracking();

    return (script) => {
        // Note: this is a hacky way of executing js. We are passing the function call as a string
        // from the DB here and then wrapping it in an anonymous function to be able to execute.
        try {
            const eventFunction = new Function(script); // eslint-disable-line no-new-func
            eventFunction();
        } catch (e) {
            console.log("Error running tracking script", e);
            execute({
                category: "ERROR",
                action: `Error running tracking script: ${script}`,
            });
        }
    };
};

export default useExecuteScript;
