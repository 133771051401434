import React from "react";
import { useSelector } from "react-redux";
import Hero from "../Hero";
import MapOfUS from "../../../MapOfUS";
import Search from "../../../Search/Search";
import { getCdnBasePath } from "../../../../support/helpers";

/**
 * Hero section of the home page. It contains the hero image, map of US and the search bar
 */
const HomeHero = () => {
    const styling = useSelector((state) => state.root.styling);
    const touchDevice = useSelector((state) => state.root.isMobile);
    const {
        showSearchBox,
        showSearchByMap,
        nicheSearchBoxText,
        nichetypePlural,
        primaryActionText,
        nicheDomain2ndLevel,
    } = styling || {};
    const heroItemMaxWidth = "550px";

    return (
        <Hero
            path={`${getCdnBasePath(nicheDomain2ndLevel)}/hero-${
                touchDevice ? "mobile" : "desktop"
            }.jpg`}
        >
            <div
                className={`container h-100 w-100 d-flex flex-column align-items-center py-5`}
            >
                <h1
                    data-test="hero-text"
                    className={`text-white text-center w-100 mb-4 position-relative`}
                    style={{ textShadow: "black 0.1rem 0.1rem 1rem" }}
                >
                    {primaryActionText}
                </h1>
                {showSearchByMap && (
                    <div
                        className="mb-4 w-75 d-none d-lg-block"
                        style={{ maxWidth: heroItemMaxWidth }}
                        data-test="hero-map"
                    >
                        <MapOfUS />
                    </div>
                )}
                {showSearchBox && (
                    <div
                        className={`w-100 px-3 px-lg-5`}
                        style={{ maxWidth: heroItemMaxWidth }}
                        data-test="hero-search"
                    >
                        <Search
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    padding: "10px",
                                }),
                                dropdownIndicator: (provided) => ({
                                    ...provided,
                                    color: "hsl(0,0%,50%)",
                                }),
                                indicatorSeparator: () => ({ display: "none" }),
                            }}
                            placeholder={nicheSearchBoxText}
                            nichetypePlural={nichetypePlural}
                            locationOnPage="Home Hero"
                        />
                    </div>
                )}
            </div>
        </Hero>
    );
};

export default HomeHero;
