import React from "react";

/**
 * It's a higher order component which check that is there any data in the window.ssrData or in ssrData for the given providerName
 * if yes, then the initial state will be the found data
 * @param {*} WrappedComponent
 * @param {*} providerName
 */
const withSSR = (WrappedComponent, initialState, providerType) => {
    return class extends React.Component {
        render() {
            // SSR
            let initState = { ...initialState };
            if (this.props.ssrData) {
                initState = { ...this.props.ssrData, ssr: true };
            } else if (this.props.initialState) {
                initState = { ...this.props.initialState, testing: true };
            } else if (
                typeof window !== "undefined" &&
                window !== null &&
                window[`${providerType}SsrData`]
            ) {
                initState = window[`${providerType}SsrData`];
            }

            return <WrappedComponent {...this.props} initState={initState} />;
        }
    };
};

export default withSSR;
