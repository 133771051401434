import React, { useEffect } from "react";
import { Modal } from "reactstrap";
import Header from "./Header";

/**
 * A slide out used for showing additional information and is the lakehomes
 * implementation of a modal.
 * @param {Object} props Modal component props
 * @param {*} props.children - Component children
 * @param {function} [props.onOpened] - Modal onOpened prop
 * @param {function} [props.onClosed] Modal onClosed prop
 * @param {function} props.toggle Modal toggle function
 * @param {string} [props.headerText] Text to display in modal header
 * @returns Drawer Component.
 */
const Drawer = ({
    children,
    onOpened,
    onClosed,
    toggle,
    headerText,
    ...props
}) => {
    const hideHeader =
        Array.isArray(children) &&
        children.find((child) => child?.type?.name === "ModalHeader");

    useEffect(() => document.body.classList.remove("overflow-hidden"), []);

    const handleOpen = () => {
        document.body.classList.add("overflow-hidden");
        if (onOpened) onOpened();
    };

    const handleClosed = () => {
        document.body.classList.remove("overflow-hidden");
        if (onClosed) onClosed();
    };

    return (
        <Modal
            modalClassName="drawer"
            {...props}
            onOpened={handleOpen}
            onClosed={handleClosed}
            toggle={toggle}
        >
            {!hideHeader && <Header toggle={toggle} headerText={headerText} />}
            {children}
        </Modal>
    );
};

export default Drawer;
