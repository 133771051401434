import React from "react";
import { useSelector } from "react-redux";
import LazyLoad from "react-lazyload";
import { HomeButtons, Lifestyles } from "./";
import Preview from "../../Preview";
import CdnImage from "../../CdnImage";
import HomeHero from "./HomeHero";

const Container = ({ indexData }) => {
    const nicheDomain2ndLevel = useSelector(
        (state) => state.root.styling.nicheDomain2ndLevel
    );
    const nichetypeSingular = useSelector(
        (state) => state.root.styling.nichetypeSingular
    );
    const touchDevice = useSelector((state) => state.root.isMobile);
    const aboutSection = useSelector(
        (state) => state.root.styling.microContentBlocks.about
    );
    const lifestylesCategories = {
        lakehomes: "12053",
        "beach-homes": "10",
    };
    const lifestylesCategory = lifestylesCategories[nicheDomain2ndLevel];

    return (
        <div data-testid="home">
            <HomeHero />
            <HomeWrapper
                key="home-buttons"
                className="bg-light"
                dataTest="home-buttons"
            >
                <HomeButtons />
            </HomeWrapper>
            {indexData?.listingsPreview?.results && (
                <HomeWrapper
                    title={indexData.listingsPreview?.dataSetTitle}
                    dataTest="featured"
                >
                    <Preview
                        className="preview"
                        listings={indexData.listingsPreview?.results}
                    />
                </HomeWrapper>
            )}
            <HomeWrapper
                key="home-content"
                className="bg-light"
                title={aboutSection.label}
                dataTest="home-content"
            >
                <div className="row">
                    <div className="col-md">
                        {nicheDomain2ndLevel !== "lakehomes" ? (
                            <div>
                                <p>
                                    We are the nation’s largest Beach-focused
                                    real estate company. Beach Homes Realty is a
                                    technology-enabled, full-service real estate
                                    brokerage employing the country’s most
                                    experienced team of beach real estate
                                    experts.
                                </p>
                                <p>
                                    We operate in multiple states (wholly owned,
                                    no franchising) and are powered by our
                                    unique real estate resources and listings
                                    here at Beach-Homes.com.
                                </p>
                                <p>
                                    Whether you are buying or selling, we
                                    provide in-depth services to market beach
                                    properties nationally. In addition to the
                                    listings on each beach, you will find
                                    information on the beaches we serve and the
                                    lifestyles that are created by the
                                    surrounding beach communities. This unique
                                    information makes us the primary repository
                                    of beach information nationwide.
                                </p>
                                <p>
                                    Our Beach Homes Lifestyles blog provides
                                    insights into beach living, tips for buying,
                                    and best practices for selling your beach
                                    home or land.
                                </p>
                            </div>
                        ) : (
                            <div>
                                {aboutSection.text && (
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: aboutSection.text,
                                        }}
                                    />
                                )}
                            </div>
                        )}
                    </div>
                    <div className="col-md">
                        <CdnImage
                            className="w-100 rounded"
                            src={aboutSection.image}
                            alt={aboutSection.alt}
                        />
                    </div>
                </div>
            </HomeWrapper>
            {lifestylesCategory && (
                <HomeWrapper
                    key="home-lifestyles"
                    title={`${nichetypeSingular} Lifestyles`}
                    dataTest="lifestyles"
                >
                    <LazyLoad once>
                        <Lifestyles
                            touchDevice={touchDevice}
                            categories={lifestylesCategory}
                        />
                    </LazyLoad>
                </HomeWrapper>
            )}
        </div>
    );
};

const HomeWrapper = ({ children, className = "", dataTest, title }) => (
    <div className={className} data-test={dataTest}>
        <div className="container-lg">
            <div className="row g-0 py-4 mx-3 mx-lg-0">
                <div className="col g-0">
                    {title && <h2 className="text-uppercase">{title}</h2>}
                    {children}
                </div>
            </div>
        </div>
    </div>
);

export default Container;
