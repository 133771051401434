import { useSelector } from "react-redux";
import useTrackEvent from "./useTrackEvent";
import { useEffect } from "react";

/**
 * Custom hook to send unique user id to Google Tag Manager.
 *
 * @return {void} This hook does not return anything.
 */
export const useTrackUser = () => {
    const uniqueId = useSelector((state) => state.root.userSession.uniqueId);
    const { trackGTM } = useTrackEvent();
    useEffect(() => {
        trackGTM({
            user_id: uniqueId,
        });
    }, [uniqueId]);
};
