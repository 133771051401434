import React from "react";
import { Controller } from "react-hook-form";
import moment from "moment";
import loadable from "@loadable/component";
import MaskedInput from "react-text-mask";
import createAutoCorrectedDatePipe from "text-mask-addons/dist/createAutoCorrectedDatePipe";

// TODO: figure out why loading Moment like this causes an error
// const Moment = loadable.lib(() => import('moment'))
const Picker = loadable.lib(() => import("react-datepicker"));

function ValidatingDatePicker({
    name,
    placeholder,
    errors,
    assembly,
    forceReadOnly,
    formFieldClasses = "",
    control,
    filterDate = null,
    excludeDates = [],
    highlightDates = [],
    defaultValueOverride,
    setError,
    clearErrors,
}) {
    const dateFormat = "YYYY-MM-DD";
    const assemblyItem = assembly.find((item) => item.id === name);
    if (!assemblyItem) return null;
    const {
        fieldInfo: {
            placeholder: infoPlaceholder,
            label,
            default: formValue,
            readonly,
        },
    } = assemblyItem;
    const defaultValue = defaultValueOverride || formValue;
    const generatedPlaceholder =
        infoPlaceholder !== "text placeholder" ? infoPlaceholder : label;
    const autoCorrectedDatePipe = createAutoCorrectedDatePipe("mm/dd/yyyy");

    return (
        <div className={errors?.[name] ? "is-invalid" : ""}>
            <Controller
                name={name}
                control={control}
                defaultValue={defaultValue || null}
                readOnly={forceReadOnly || readonly === 1}
                placeholder={placeholder || generatedPlaceholder}
                render={({ field }) => (
                    <Picker fallback={<div>Loading....</div>}>
                        {({ default: DatePicker }) => (
                            <DatePicker
                                className={`px-2 w-100 form-control ${formFieldClasses} ${
                                    errors?.[name] ? "is-invalid" : ""
                                }`}
                                wrapperClassName="w-100"
                                selected={
                                    field.value
                                        ? moment(
                                              field.value,
                                              `${dateFormat} HH:mm`
                                          ).toDate()
                                        : field.value
                                }
                                onChange={(value) =>
                                    field.onChange(
                                        value
                                            ? moment(value).format(dateFormat)
                                            : undefined
                                    )
                                }
                                onCalendarClose={() => {
                                    if (field.value === null) {
                                        setError(name, {
                                            type: "custom",
                                            message:
                                                "Please enter a valid date.",
                                        });
                                        setTimeout(
                                            () => clearErrors(name),
                                            3000
                                        );
                                    }
                                }}
                                filterDate={filterDate}
                                excludeDates={excludeDates.map((value) =>
                                    moment(
                                        value,
                                        `${dateFormat} HH:mm`
                                    ).toDate()
                                )}
                                highlightDates={highlightDates.map((value) =>
                                    moment(
                                        value,
                                        `${dateFormat} HH:mm`
                                    ).toDate()
                                )}
                                minDate={moment(new Date(), dateFormat)
                                    .add(1, "d")
                                    .toDate()}
                                maxDate={moment(new Date(), dateFormat)
                                    .add(3, "months")
                                    .toDate()}
                                customInput={
                                    <MaskedInput
                                        pipe={autoCorrectedDatePipe}
                                        mask={[
                                            /\d/,
                                            /\d/,
                                            "/",
                                            /\d/,
                                            /\d/,
                                            "/",
                                            /\d/,
                                            /\d/,
                                            /\d/,
                                            /\d/,
                                        ]}
                                        keepCharPositions={true}
                                        guide={true}
                                    />
                                }
                            />
                        )}
                    </Picker>
                )}
            />
        </div>
    );
}

export default ValidatingDatePicker;
