import { useState, useCallback } from "react";
import {
    getBaseUrl,
    buildQueryParams,
    fetchOverlord,
} from "../support/helpers";

export const getAssembly = async (
    sessionId,
    assemblyId,
    ignoreCache,
    params
) => {
    const query = buildQueryParams({ ignoreCache, ...params });
    const response = await fetchOverlord(
        `${getBaseUrl()}/assemblies/${assemblyId}${query}`,
        {
            headers: {
                "Content-Type": "application/json",
                sessionId,
            },
        }
    );
    return response;
};

export const useGetAssembly = (initialResults = null) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState(initialResults);

    const execute = async (
        sessionId,
        assemblyId,
        ignoreCache = false,
        params
    ) => {
        try {
            setIsLoading(true);
            const results = await getAssembly(
                sessionId,
                assemblyId,
                ignoreCache,
                params
            );
            setData(results);
            if (error) {
                setError(null); // resetting error
            }
            setIsLoading(false);
            return results;
        } catch (e) {
            setError(e);
            setIsLoading(false);
            throw e;
        }
    };

    return {
        isLoading,
        error,
        data,
        execute: useCallback(execute, [error]), // to avoid infinite calls when inside a `useEffect`
        clear: () => setData(null),
    };
};
