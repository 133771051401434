import React from "react";
import { Badge } from "reactstrap";

/**
 * Renders a list of badges based on the provided criteria.
 *
 * @param {number} showLimit - Total number of badges to show.
 * @param {boolean} isFeatured - Show Featured Badge.
 * @param {boolean} isPriceChanged - Show Price Change Badge.
 * @param {boolean} isNew - Show New Badge.
 * @param {boolean} isWaterfront - Show Waterfront Badge.
 * @param {boolean} isOffLake - Show Off Lake Badge.
 * @param {boolean} isOffMarket - Show Off Market Badge.
 * @param {string} data-test - Test attribute name.
 * @param {object} props - Additional props for the component.
 * @return {JSX.Element} Rendered list of badges.
 */
const Badges = ({
    showLimit = 5,
    isFeatured,
    isPriceChanged,
    isNew,
    isWaterfront,
    isOffLake,
    isOffMarket,
    "data-test": dataTest = "badges",
    ...props
}) => {
    const badgeClassName = "mx-1";
    const badgeList = [];

    if (isWaterfront && badgeList.length + 1 <= showLimit) {
        badgeList.push({
            label: "Waterfront",
            props: {
                key: "badge-waterfront",
                color: "primary-light",
                id: "waterfrontPopover",
                className: badgeClassName,
            },
        });
    }
    if (isPriceChanged && badgeList.length + 1 <= showLimit) {
        badgeList.push({
            label: "Price Changed",
            props: {
                key: "badge-price",
                color: "primary",
                className: badgeClassName,
            },
        });
    }
    if (isNew && badgeList.length + 1 <= showLimit) {
        badgeList.push({
            label: "New Listing",
            props: {
                key: "badge-new",
                color: "quaternary",
                className: badgeClassName,
            },
        });
    }
    if (isFeatured && badgeList.length + 1 <= showLimit) {
        badgeList.push({
            label: "Featured",
            props: {
                key: "badge-featured",
                color: "primary-dark",
                className: badgeClassName,
            },
        });
    }
    if (isOffLake && badgeList.length + 1 <= showLimit) {
        badgeList.push({
            label: "Off Lake",
            props: {
                key: "badge-offlake",
                color: "gray",
                className: badgeClassName,
            },
        });
    }
    if (isOffMarket && badgeList.length + 1 <= showLimit) {
        badgeList.push({
            label: "Off Market",
            props: {
                key: "badge-offmarket",
                color: "gray",
                className: badgeClassName,
            },
        });
    }

    return (
        <div data-test={dataTest} {...props}>
            {badgeList.map(({ label, props: { key, ...props } }) => (
                <Badge key={key} {...props}>
                    {label}
                </Badge>
            ))}
        </div>
    );
};

export default Badges;
