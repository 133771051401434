import { useState, useCallback } from "react";
import { getBaseUrl, fetchOverlord } from "../support/helpers";

export const getStyling = async (sessionId, baseUrl, headers = {}) => {
    const response = await fetchOverlord(`${baseUrl}/styling`, {
        headers: {
            "Content-Type": "application/json",
            sessionId,
            ...headers,
        },
    });
    return response;
};

export const useGetStyling = (initialResults = null) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState(initialResults);

    const execute = async (sessionId) => {
        try {
            setIsLoading(true);
            const baseUrl = getBaseUrl();
            const results = await getStyling(sessionId, baseUrl);
            setData(results);
            if (error) {
                setError(null); // resetting error
            }
            setIsLoading(false);
            return results;
        } catch (e) {
            setError(e);
            setIsLoading(false);
            throw e;
        }
    };

    return {
        isLoading,
        error,
        data,
        execute: useCallback(execute, [error]), // to avoid infinite calls when inside a `useEffect`
        clear: () => setData(null),
    };
};
