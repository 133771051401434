import { createSlice } from "@reduxjs/toolkit";
import { prependDomain } from "../support/helpers";

export const pageNameByType = {
    ssr: false,
    isDev: false,
    isMobile: false,
    index: "Home",
    state: "State",
    nicheItem: "Niche",
    nicheItem_development: "Development",
    listing: "Listing",
    agent: "Agent",
    states_agents: "StatesAgents",
    agents: "Agents",
};

// Getting _ params - These are used for custom modes
// They are added to state without the _
let appParams = {};
let domain = "";
if (typeof window !== "undefined" && window !== null) {
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.forEach((value, key) => {
        if (key.startsWith("_")) appParams[key.substring(1)] = value;
    });
    domain = window.origin.includes("localhost")
        ? "https://www.lakehomes.com"
        : window.origin;
}

export const initialState = {
    mode: "normal",
    isFetching: false,
    ...appParams,
    userSession: {},
    assemblies: {},
    domain,
    serverOrigin: undefined,
    styling: undefined,
    navItems: [
        {
            link: "/browse-lake-home",
            label: "Browse Lake Homes",
            shortLabel: "Find",
            columns: 3,
            children: [],
        },
        {
            type: "resource",
            link: "/info/sell-a-lake-home",
            label: "Sell Lake Homes",
            shortLabel: "Sell",
        },
        {
            type: "resource",
            link: "/agents",
            label: "Lake Agents",
            shortLabel: "Agents",
        },
        {
            link: `${domain}/info/articles-and-news`,
            label: "Lake Lifestyles",
            shortLabel: "Lifestyles",
        },
        {
            link: "#",
            label: "About Us",
            shortLabel: "About",
            columns: 1,
            children: [
                {
                    type: "resource",
                    link: `${domain}/info/lake-real-estate-market-reports`,
                    label: "Lake Market Reports",
                },
                {
                    type: "resource",
                    link: `${domain}/info/articles-and-news`,
                    label: "Lake Lifestyles",
                },
                {
                    type: "company",
                    link: `${domain}/info/become-an-agent`,
                    label: "Become an Agent",
                },
                {
                    type: "resource",
                    link: `${domain}/info/advertising-real-estate-for-sale`,
                    label: "Advertising Real Estate For Sale",
                },
                {
                    type: "company",
                    link: `${domain}/info/ceo-message`,
                    label: "CEO Message",
                },
                {
                    type: "company",
                    link: `${domain}/info/employment`,
                    label: "Employment",
                },
                {
                    type: "resource",
                    link: `${domain}/info/frequently-asked-questions`,
                    label: "Frequently Asked Questions",
                },
                {
                    type: "company",
                    link: `${domain}/info/contact-us`,
                    label: "Contact Us",
                },
            ],
        },
    ],
    savedProperties: [],
};

export const assebliesWithUserData = [
    "CONTACT_US-Newsletter",
    "CONTACT_US_AGENT",
    "VERIFY-EMAIL_REQUEST",
    "CONTACT_US_SHOWING",
    "CONTACT_US_MOREINFO",
    "EMAIL_LISTING2FRIEND",
    "CHANGE-EMAIL_REQUEST",
    "CONTACT_US_FEATURED AGENT",
];

const rootSlice = createSlice({
    name: "root",
    initialState,
    reducers: {
        setMode: (state, action) => {
            state.mode = action.payload;
        },
        setIsMobile: (state, action) => {
            state.isMobile = action.payload;
        },
        setIsFetchingPage: (state, action) => {
            state.isFetching = action.payload || false;
        },
        setPageError: (state, action) => {
            state.pageError = action.payload;
        },
        setServerOrigin: (state, action) => {
            state.serverOrigin = action.payload;
        },
        setNavItems: (state, action) => {
            const { styling: { states, microNav } = {}, origin: rawOrigin } =
                action.payload;
            const navItems = [...(microNav?.header || state.navItems)];

            if (states && navItems[0].children.length === 0) {
                Object.values(states).forEach((usState) => {
                    if (usState.isStateServed)
                        navItems[0].children.push({
                            type: "state",
                            link: usState.valueLink,
                            label: usState.stateName,
                            locationState: { stateId: usState.stateCode },
                        });
                });
            }

            // We need to replace all links on /info to be absolute links so they will link to
            // the the wordpress site properly.
            let origin = rawOrigin;
            if (!origin && typeof window !== "undefined" && window !== null) {
                origin = window.origin;
            }
            navItems.forEach((navItem) => {
                if (navItem?.link) {
                    navItem.link = prependDomain(origin, navItem.link);
                }
                if (navItem?.children) {
                    navItem.children = navItem.children.map((childNavItem) => {
                        childNavItem.link = prependDomain(
                            origin,
                            childNavItem.link
                        );
                        return childNavItem;
                    });
                }
            });
            state.navItems = navItems;
        },
        setStyling: (state, action) => {
            const styling = { ...action.payload };
            delete styling.userInfo4Session;
            state.styling = styling;
        },
        setSession: (state, action) => {
            state.userSession.sessionID = action.payload;
        },
        setIsLoggedIn: (state, action) => {
            state.isLoggedIn = action.payload;
        },
        setUserInfo: (state, action) => {
            // Setting isLoggedIn in state
            const isLoggedIn =
                typeof action.payload.userID === "number" &&
                action.payload.userID !== undefined;
            state.isLoggedIn = isLoggedIn;

            // Setting isEntitled in state
            const mcAccess =
                action.payload?.webAccess4?.["MICRO-CONTENT"] || {};
            state.isEntitled = Object.values(mcAccess).some((val) => !!val);

            state.userSession = action.payload;
        },
        setUserInfoByKey: (state, action) => {
            const { key, value } = action.payload;
            if (key && value) state.userSession[key] = value;
        },
        setAssemblies: (state, action) => {
            state.assemblies = { ...state.assemblies, ...action.payload };
        },
        updateSignupPwEstablishAssemblyEmail: (state, action) => {
            state.assemblies["SIGNUP-PW_ESTABLISH_REQUEST"].data.email =
                action.payload;
        },
        removeAssembly: (state, action) => {
            delete state.assemblies[action.payload];
        },
        removeAssemblies: (state, action) => {
            if (Array.isArray(action.payload)) {
                action.payload.forEach((assemblyName) => {
                    delete state.assemblies[assemblyName];
                });
            }
        },
        setSavedProperties: (state, action) => {
            if (action.payload) {
                action.payload.forEach((nicheItem) =>
                    nicheItem.properties.forEach((prop) => {
                        const index = state.savedProperties.indexOf(
                            prop.more.id
                        );
                        if (index === -1)
                            state.savedProperties.push(prop.more.id);
                    })
                );
            }
        },
        addSavedProperty: (state, action) => {
            const index = state.savedProperties.indexOf(action.payload);
            if (index === -1) {
                state.savedProperties.push(action.payload);
            }
        },
        removeSavedProperty: (state, action) => {
            const index = state.savedProperties.indexOf(action.payload);
            if (index > -1) {
                state.savedProperties.splice(index, 1);
            }
        },
        removeSavedProperties: (state) => {
            state.savedProperties = [];
        },
    },
});

// Extract the action creators object and the reducer
const { actions, reducer } = rootSlice;
// Extract and export each action creator by name
export const {
    setMode,
    setPageError,
    setIsFetchingPage,
    setNavItems,
    setSession,
    setIsLoggedIn,
    setUserInfo,
    setUserInfoByKey,
    setStyling,
    setAssemblies,
    updateSignupPwEstablishAssemblyEmail,
    removeAssembly,
    removeAssemblies,
    setIsMobile,
    setServerOrigin,
    setSavedProperties,
    addSavedProperty,
    removeSavedProperty,
    removeSavedProperties,
} = actions;
// Export the reducer, either as a default or named export
export default reducer;
