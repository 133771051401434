import React from "react";
import styled from "styled-components";
import Loading from "../Loading";

/**
 * Creates a translucent curtain over the wrapped content.
 * @param {ReactComponent} children - React component children
 * @param {string} className - Wrapper className
 * @param {boolean} show - show/hide curtain toggle
 * @param {boolean} withLoading - Adds a loading element to the curtain
 * @param {string} label - Adds text to curtain
 */
const Curtain = ({
    children,
    className = "",
    show = false,
    withLoading,
    label,
}) => (
    <div className={`${className} position-relative`}>
        {withLoading && show && (
            <Loading
                style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    zIndex: 2000,
                }}
                loadingLabel={label}
            />
        )}
        {!withLoading && label && show && (
            <div
                style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    zIndex: 10,
                }}
            >
                {label}
            </div>
        )}
        {children}
        <Cloth show={show} />
    </div>
);

const Cloth = styled.div`
    display: ${(props) => (props.show ? "block" : "none")};
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.6;
    background-color: #ffffff;
`;

export default Curtain;
