import React from "react";
import styled from "styled-components";
import { ModalHeader } from "reactstrap";
import { useSelector } from "react-redux";
import { FaTimes } from "react-icons/fa";
import CdnImage from "../CdnImage";

const Header = ({ toggle, headerText }) => {
    const nicheName = useSelector((state) => state.root.styling.nicheName);

    return (
        <StyledModalHeader className="bg-primary rounded-0 p-2">
            <div className=" d-flex align-items-center">
                {!headerText ? (
                    <div>
                        <CdnImage
                            className="color-primary p-0 pe-1"
                            style={{ height: "40px" }}
                            src="/logo-white.png"
                            alt={nicheName}
                        />
                    </div>
                ) : (
                    <div
                        className="d-flex align-items-center"
                        style={{ overflow: "hidden", marginRight: "20px" }}
                    >
                        <CdnImage
                            className="color-primary p-0 pe-1"
                            style={{ height: "40px" }}
                            src="/icon-white.png"
                            alt={nicheName}
                        />
                        <div className="ms-2 d-inline-block text-white text-truncate">
                            {headerText}
                        </div>
                    </div>
                )}
                <div className="d-flex ms-auto" style={{ fontSize: "20px" }}>
                    <div onClick={toggle}>
                        <FaTimes className="text-white" />
                    </div>
                </div>
            </div>
        </StyledModalHeader>
    );
};

const StyledModalHeader = styled(ModalHeader)`
    .modal-title {
        width: 100%;
    }
`;

export default Header;
