/*
 * ------------------------------------------------------------------------------------
 * DESCRIPTION:
 * ------------------------------------------------------------------------------------
 *      ReCaptchaFormItem component
 *
 * ------------------------------------------------------------------------------------
 * VERSION HISTORY: (NOTE: The version variables must be updated manually.)
 * ------------------------------------------------------------------------------------
 *                   Changed By
 *      Date         Initials    Version #  Sub-Version  Notes
 *      -----------  ----------  ---------  -----------  ------------------------------
 *      2022-11-22   BL          22326      1            Re-structured files and renamed to index.js
 *
 */
import React, { useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { getConfigItem } from "../../support/helpers";

const ReCaptchaFormItem = ({ register, setValue, trigger }) => {
    const key = getConfigItem("REACT_APP_RECAPTCHA_KEY");

    useEffect(() => {
        if (key) {
            register({ name: "captchaToken" }, { required: true });
        }
    }, [key, register]);

    const handleCaptcha = (token) => {
        setValue("captchaToken", token);
        trigger("captchaToken");
    };

    return (
        <>
            {key && (
                <ReCAPTCHA
                    sitekey={key}
                    onChange={handleCaptcha}
                    data-testid="reCaptchaFormItem"
                />
            )}
        </>
    );
};

export default ReCaptchaFormItem;
