import { useState, useCallback } from "react";
import { getBaseUrl, fetchOverlord } from "../support/helpers";

export const userProperties = async (userId, options, propertyId) => {
    const idPathPart = propertyId ? `/${propertyId}` : "";
    const response = await fetchOverlord(
        `${getBaseUrl()}/users/${userId}/userproperties${idPathPart}`,
        options
    );

    return response;
};

export const useUserProperties = (initialResults = null) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState(initialResults);

    const execute = async (userId, options = {}, propertyId = undefined) => {
        try {
            setIsLoading(true);
            const results = await userProperties(userId, options, propertyId);
            setData(results);
            if (error) setError(null);
            setIsLoading(false);
            return results;
        } catch (e) {
            setError(e);
            setIsLoading(false);
        }
    };

    return {
        isLoading,
        error,
        data,
        execute: useCallback(execute, []), // to avoid infinite calls when inside a `useEffect`
        clear: () => setData(null),
    };
};
